import { Ripple } from 'primereact/ripple';
import React, { Component } from 'react'

export default class ProfileAttachment extends Component {


    render() {
        const { isShowRemoveBtn, fileInfo, imageSrc, isBlob } = this.props;


        return (
            <div className="col-12 md:col-6 lg:col-3 mb-3 lg:mb-0">
                <div className="p-2 w-full">
                    <div className="relative">
                        {
                            isBlob ? <img
                                src={URL.createObjectURL(fileInfo)}
                                className="w-full"
                            /> : <img
                                src={imageSrc}
                                className="w-full"
                            />
                        }
                        {
                            isShowRemoveBtn && <button
                                type="text"
                                onClick={() => {
                                    this.props._removefile(fileInfo)
                                }}
                                className="p-ripple p-link w-3rem h-3rem surface-0 hover:surface-200 border-circle shadow-2 inline-flex align-items-center justify-content-center absolute transition-colors transition-duration-300"
                                style={{ top: "1rem", right: "1rem" }}
                            >
                                <i className="pi pi-times text-2xl text-500"></i>
                                <Ripple />
                            </button>
                        }

                    </div>
                </div>
            </div>
        )
    }
}
