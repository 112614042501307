import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from './../../Utils/formHelper';
import { Button } from 'primereact/button';
import { trimObj } from './../../Utils';

import Service from './../../Service';
import { saveUserSession } from './../../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../session/index'
import LoadingComponent from './../../LoadingComponent';
import ServerlessService from './../../Service/ServerlessService';
import VerifyOTPAchangeP from './verifyOTPAchangeP';



const loginFormFields = [
    {
        "Type": "Number",
        "Label": "Phone Number",
        "FieldName": "phoneNumber",
        "Required": true
    }
];

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(loginFormFields, {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isNewUser: false,
            isShowVerifyOtp: false
        }

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    onLogin = () => {
        const formStatus = isFormValid(loginFormFields, this.formFields.formValidations, trimObj(this.state.user));


        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (isCompleteFormValid) {
            let data = this.state.user;
            data.orgCode = this.props.orgCode;

            this.setState({
                isLoading: true
            });
            const url = `/admin/reset-password`;

            this.serverlessService.post(url, data, true).then((res) => {

                if (res && res.status && res.res.status) {
                    if (res.res.statuscode == 2) {
                        this.setState({
                            userId: res.res.user,
                            isLoading: false,
                            isShowVerifyOtp: true
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        })
                    }

                } else {

                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }




    }

    componentDidMount() {


        if (this.props.isSessionChecked && this.props.authenticated) {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps) {


        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && this.props.authenticated)) {
            this.props.history.push('/')
        }
    }

    render() {
        const { user, formValidations, isNewUser, isShowVerifyOtp, userId, isResetPassword } = this.state;
        return (<>
            <div className=''>

                <div className="flex align-items-center justify-content-between mb-7">
                    <span className="text-2xl font-medium text-900">Login to {this.props.orgName}</span>
                </div>
                {isShowVerifyOtp ? <>
                    <VerifyOTPAchangeP userId={userId} phoneNumber={user.phoneNumber} />
                </> : <>
                    <div>
                        <label htmlFor="phoneNumber" className="block text-900 font-medium mb-2">Phone Number</label>
                        <InputText className='w-full' autoComplete="off" value={user.phoneNumber} onChange={(e) => { onNumberChange(e.target.value, 'phoneNumber', this, loginFormFields, user, formValidations, 'user', 'formValidations') }} />
                        {formValidations && !formValidations.fields['phoneNumber'].isValid && <p className="p-error">{formValidations.fields['phoneNumber'].errorMsg}</p>}
                    </div>
                    <div>
                        <p className='font-medium my-4 cursor-pointer transition-colors transition-duration-150'>Remember Password? <span className='text-primary' onClick={this.props.showLogin} >Login</span></p>
                    </div>
                    <Button label="Get OTP" onClick={this.onLogin} className="w-full py-3 font-medium" />
                </>}
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
        </>)
    }
}





const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgCode: state?.orgData?.orgCode,
    isLoading: state?.orgData?.isLoading,
    orgName: state?.orgData?.orgName,
});



export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(ResetPassword)));
