//const EMAIL_SERVER_URL = 'http:// localhost:7878/api'
//const EMAIL_SERVER_URL = 'api'
//const EMAIL_SERVER_URL = 'http://38.242.141.151:7878/api'
//const EMAIL_SERVER_URL = 'https://sendshortly.com/api';


// // dev
// var EMAIL_SERVER_URL = 'http:// localhost:7877/api';
// var SERVERLESS_URL = 'http:// localhost:7877/api';
// var SMS_SERVER_URL = 'http:// localhost:7877/api';
// var OPEN_API_ENDPOINT = 'http:// localhost:7877/api';



var EMAIL_SERVER_URL = 'https://consultant.sendshortly.com/api';
var SERVERLESS_URL = 'https://consultant.sendshortly.com/api';
var SMS_SERVER_URL = 'https://consultant.sendshortly.com/api';
var OPEN_API_ENDPOINT = 'https://consultant.sendshortly.com/api';


//const TINY_BASE_URL = 'http:// localhost:7878'

// sas-prod
// const EMAIL_SERVER_URL = 'https://api.sendshortly.com/api';
// const SMS_SERVER_URL = 'https://apis.sendshortly.com/api';
// const SERVERLESS_URL = 'https://ngo63gcntf.execute-api.ap-south-1.amazonaws.com/prod/api'
// const OPEN_API_ENDPOINT = 'https://sms.sstiny.com/api';



const S3_UPLOAD_CONFIG = {
    bucketName: 'macademy-profiles',
    dirName: 'uploads/', /* optional */
    region: 'ap-south-1',
    accessKeyId: 'AKIAJWGD7NJSR76CODMQ',
    secretAccessKey: 'Cy36g0hM7QTgRfTGCUkoPfZGRqcJHLuloHylVDvn',
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
};
const REACT_APP_RUNNING = process.env.REACT_APP_RUNNING;

// if (REACT_APP_RUNNING == 'cloud') {

//     EMAIL_SERVER_URL = 'http://localhost:7877/api';
//     SERVERLESS_URL = 'http://localhost:7877/api';
//     SMS_SERVER_URL = 'http://localhost:7877/api';
//     OPEN_API_ENDPOINT = 'http://localhost:7877/api';

// }




const TINY_BASE_URL = 'sstiny.in'

// const S3_UPLOAD_PRE_SIGN_URL = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url-mat';
const S3_UPLOAD_PRE_SIGN_URL = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
//const TINY_BASE_URL = 'sms.sstiny.com'
// const CLOUDFRONT_ENDPOINT = 'https://ds290biduyry8.cloudfront.net/';

const CLOUDFRONT_ENDPOINT = 'https://d1wqfechwjsewm.cloudfront.net/';






module.exports = {
    EMAIL_SERVER_URL,
    SERVERLESS_URL,
    OPEN_API_ENDPOINT,
    SMS_SERVER_URL,
    TINY_BASE_URL,
    CLOUDFRONT_ENDPOINT,
    S3_UPLOAD_CONFIG,
    S3_UPLOAD_PRE_SIGN_URL
}