import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from './../../Utils/formHelper';
import { Button } from 'primereact/button';
import { trimObj } from './../../Utils';
import registerFormFields from './registerForm.json';
import Service from './../../Service';
import { saveUserSession } from './../../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../session/index'
import LoadingComponent from './../../LoadingComponent';
import VerifyOTP from './verifyOTP';
import ServerlessService from './../../Service/ServerlessService';
import { Divider } from 'primereact/divider';




class Register extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(registerFormFields, {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isNewUser: false,
            isShowVerifyOtp: false
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    isAdditionalValid = () => {

        let user = JSON.parse(JSON.stringify(this.state.user));
        let { formValidations } = this.state;
        let isValid = true;
        if (user.password != user.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }


    onRegisterSuccess = (user) => {



    }


    onRegister = () => {


        const formStatus = isFormValid(registerFormFields, this.formFields.formValidations, trimObj(this.state.user));


        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }


        if (isCompleteFormValid) {
            let data = trimObj(this.state.user);
            data.orgCode = this.props.orgCode;

            this.setState({
                isLoading: true
            });
            const url = `/admin/sign-up`;

            this.serverlessService.post(url, data, true).then((res) => {



                if (res && res.status && res.res.status) {

                    if (res.res.statuscode == 2) {
                        this.setState({
                            userId: res.res.user,
                            isLoading: false,
                            isShowVerifyOtp: true
                        });
                    } else {


                    }



                } else {

                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })



        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.authenticated != this.props.authenticated && this.props.authenticated) {
            this.props.history.push('/')
        }
    }




    render() {
        const { user, formValidations, isNewUser, isShowVerifyOtp, userId } = this.state;
        return (<>
            <div className=''>
                <div>

                    {isShowVerifyOtp ? <>
                        <VerifyOTP userId={userId} />
                    </> : <>
                        <div>

                            <label htmlFor="phoneNumber" className="block text-900 font-medium">Phone Number</label>
                            <InputText
                                className='w-full'
                                autoComplete="off" value={user.phoneNumber}
                                onChange={(e) => { onNumberChange(e.target.value, 'phoneNumber', this, registerFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['phoneNumber'].isValid && <p className="p-error">{formValidations.fields['phoneNumber'].errorMsg}</p>}
                            <label htmlFor="phoneNumber" className="block text-900 font-medium mt-3">Password</label>
                            <InputText value={user.password}
                                type="password"
                                autoComplete="new-password"
                                className='w-full'
                                onChange={(e) => { onTextChange(e.target.value, 'password', this, registerFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
                            <label htmlFor="phoneNumber" className="block text-900 font-medium mt-3">Confirm Password</label>
                            <InputText value={user.passwordConfirm}
                                type="password"
                                autoComplete="new-password"
                                className='w-full'
                                onChange={(e) => { onTextChange(e.target.value, 'passwordConfirm', this, registerFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['passwordConfirm'].isValid && <p className="p-error">{formValidations.fields['passwordConfirm'].errorMsg}</p>}
                            <label htmlFor="phoneNumber" className="block text-900 font-medium mt-3">Email</label>
                            <InputText value={user.email}
                                className='w-full'
                                onChange={(e) => { onEmailChange(e.target.value, 'email', this, registerFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}

                            <label htmlFor="phoneNumber" className="block text-900 font-medium mt-3">Your Name</label>
                            <InputText value={user.name}
                                autoComplete="new-password"
                                className='w-full'
                                onChange={(e) => { onTextChange(e.target.value, 'name', this, registerFormFields, user, formValidations, 'user', 'formValidations') }} />
                            {formValidations && !formValidations.fields['name'].isValid && <p className="p-error">{formValidations.fields['name'].errorMsg}</p>}
                        </div>
                        <Button label='Register' className='w-full py-3 font-medium mt-6'
                            onClick={this.onRegister}
                        />
                        <Divider align="center" className="my-4">
                            <span className="text-600 font-normal text-sm">OR</span>
                        </Divider>
                        <div className="mt-6 text-center text-600">
                            Are you registed? <span onClick={this.props.onLoginClick} tabIndex="0" className="font-medium text-primary">Login</span>
                        </div>

                    </>}
                </div>
            </div>
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }

        </>)
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgCode: state?.orgData?.orgCode,
    isLoading: state?.orgData?.isLoading,
});


export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(Register)));
