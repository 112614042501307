import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import { Dropdown } from "primereact/dropdown";
import { Months, PaymentModes, Status } from "../../../Utils/constants";

class PolicyHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            policy: null,
        };
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = (id) => {
        if (this.props.editPolicy) {
            this.setState({
                isLoading: true,
            });

            const url = `/chitfunds/policy/history`;
            this.serverlessService
                .put(url, this.state.policy, true)
                .then((res) => {

                    if (res && res?.status && res?.res?.status) {
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occured",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: e.message,
                        life: 3000,
                    });
                });
        } else {
            this.setState({
                isLoading: true,
            });
            let policyId = this.props?.policyId;
            let policy = this.state.policy;
            let _id = id;
            const url = `/chitfunds/policy/history`;
            this.serverlessService
                .post(url, { policy, policyId, _id }, true)
                .then((res) => {

                    if (res && res?.status && res?.res?.status) {
                        this.setState();
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occured",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };



    getMembersByPolicyId = () => {
        this.setState({
            isLoading: true,
            members: null,
        });
        let id = this.props.policyId;
        const url = `/chitfunds/policy/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        members: res?.res?.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    monthBody = (rowData) => {
        console.log(rowData, "rowDataaaaaa");
        return (
            <Dropdown
                onChange={(e) =>

                    this.setState((prevState) => {
                        return {
                            policy: {
                                ...prevState.policy,
                                month: e.value,
                            },
                        };
                    })
                }

                value={this.state.policy?.month}
                options={Months}
                optionLabel="name"
                optionValue="id"
            ></Dropdown>
        );
    };

    paymentStatus = () => {
        return (
            <Dropdown
                onChange={(e) =>

                    this.setState((prevState) => {
                        return {
                            policy: {
                                ...prevState.policy,
                                status: e.value,
                            },
                        };
                    })
                }

                value={this.state.policy?.status}
                options={Status}
                optionLabel="type"
                optionValue="id"
            ></Dropdown>
        );
    };


    paymentType = (rowData) => {
        return (
            <Dropdown
                onChange={(e) =>

                    this.setState((prevState) => {
                        return {
                            policy: {
                                ...prevState.policy,
                                paymentType: e.value,
                            },
                        };
                    })
                }

                value={this.state.policy?.paymentType}
                options={PaymentModes}
                optionLabel="type"
                optionValue="id"
            ></Dropdown>
        );
    };

    actionBody = (rowData) => {
        return (
            <Button label="Save"
                onClick={() => this.addOrEdit(rowData?.selectedMember?._id)}
                className="p-button-primary "

            >

            </Button>
        )
    }




    componentDidMount() {
        this.getMembersByPolicyId();
    }

    render() {
        const { policy } = -this.state;
        // console.log(this.state.members, "members");
        console.log(this.state.policy, "policy 555555");
        return (
            <>
                <div className="p-4 ">
                    <DataTable value={this.state.members}>
                        <Column
                            header="Name"
                            field="selectedMember.name"
                        ></Column>
                        <Column
                            header="Month"
                            body={(rowData) => this.monthBody(rowData)}
                        ></Column>

                        <Column
                            header="Amount To Be Paid"
                            body={(rowData) => <InputText value={this.props?.monthlyPay}></InputText>}
                        ></Column>


                        <Column
                            header="Payment Status"
                            body={(rowData) => this.paymentStatus(rowData)}
                        ></Column>

                        <Column
                            header="Payment Type"
                            body={(rowData) => this.paymentType(rowData)}
                        ></Column>

                        <Column
                            header="Payment Type"
                            body={(rowData) => this.actionBody(rowData)}
                        ></Column>

                    </DataTable>
                </div>
            </>
        );
    }
}

export default PolicyHistory;
