import React, { Component } from 'react'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session/index'
import { BasicLazyParams } from './../../../Utils/constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { warningDailogInit } from './../../../Utils';

import CreateReseller from './createOrg';
import Authorize, { PERMISSIONS } from './../../../session/authorize';
import ServerlessService from './../../../Service/ServerlessService';
import LoadingComponent from './../../../LoadingComponent';

class Organization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddTemplate: false,
            appointments: [],
            lazyParams: { ...BasicLazyParams, sortField: 'bookingSlot', sortOrder: 1 },
            globalSearch: '',
            isShowTemplate: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
        }

        this.serverlessService = new ServerlessService();
    }


    showAddReseller = () => {
        this.setState({ isShowAddTemplate: true, editReseller: false });

    }


    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {

                currentFilter: currentFilter,
                lazyParams: BasicLazyParams
            }
        }, this.getResellers);
    }


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {

                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getResellers);
    }

    onPage = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getResellers);
    }

    onSort = (event) => {

        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event
                }
            };
        }, this.getResellers);
    }

    onFilter = (event) => {

        event['first'] = 0;
        this.setState((prevState) => {

            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters
                }
            };
        }, this.getResellers);
    }



    getResellers = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: []
        });
        const url = `/organization/resellers-all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, { status: this.state.currentFilter }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    appointments: res.res.data,
                    totalRecords: res.res.totalRecords
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }

    showTemplate = (template) => {

        this.setState({
            isShowTemplate: true,
            template: template
        })
    }


    editReseller = (template) => {
        const url = `/organization/details/${template._id}`;
        this.serverlessService.get(url, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    isShowAddTemplate: true,
                    isLoading: false,
                    editReseller: res.res.data
                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }
        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    deleteTemplate = (rowData) => {
        let data = { ...rowData }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You want delete the template, it can't be undone once deleted`
            },
            editReseller: data
        });

    }

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        });

        const url = `/template/delete`;
        this.serverlessService.post(url, { _id: this.state.editReseller._id }, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    editReseller: null,
                    isLoading: false
                }, () => {
                    this.getResellers();
                })


                this.toast.show({ severity: 'success', summary: 'Success', detail: 'Templates deleted successfully.', life: 3000 });
            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }



    actionsTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">

                <Authorize permId={PERMISSIONS.TEMPLATES_EDIT} >
                    <span data-pr-tooltip="Edit Reseller" data-pr-position="bottom" className={`sp-ml10 edit${rowData._id.substr(2)}`} onClick={() => { this.editReseller(rowData) }}>
                        <i className='pi pi-pencil' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.edit${rowData._id.substr(2)}`} />
                </Authorize>



            </div>
        </>
        );
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    onSaveTemplate = (isEdit) => {
        this.toast.show({ severity: 'success', summary: `${isEdit ? 'Added' : 'Modified'}`, detail: `Template ${isEdit ? 'Edited' : 'Created'} Succesfully.`, life: 3000 });


        this.setState({
            isShowAddTemplate: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getResellers();
        })

    }


    componentDidMount() {


        this.getResellers();
    }

    showCreditManagement = (editRow) => {

        this.setState({
            editSeller: editRow,
            isShowCreditsAdd: true
        });


    }


    onCreditsAdd = () => {
        this.toast.show({ severity: 'success', summary: `Credits added`, detail: `Credits added succesfully.`, life: 3000 });
        this.setState({
            isShowCreditsAdd: false,
            lazyParams: BasicLazyParams
        }, () => {
            this.getResellers();
        })

    }

    render() {



        return (<div>

            <div className='h-screen overflow-auto p-4'>
                <span className="text-1000 font-medium text-4xl  mb-3">
                    All Resellers
                </span>
                <br />
                <Authorize permId={PERMISSIONS.TEMPLATES_ADD} >
                    <Button label="Add Reseller" className='p-button-primary'
                        onClick={this.showAddReseller} />
                </Authorize><br /><br />
                {
                    this.state.isShowAddTemplate && <CreateReseller
                        reseller={this.state.editReseller} onHide={() => {

                            this.setState({
                                isShowAddTemplate: false
                            })
                        }}

                        onSave={this.onSaveTemplate}
                    />
                }
                <Authorize permId={PERMISSIONS.TEMPLATES_LIST} >
                    <div>
                        <DataTable ref={(el) => this.dt = el}
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            value={this.state.appointments}
                            dataKey="_id"
                            lazy
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            paginator
                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            globalFilter={this.state.globalFilter}

                        >
                            <Column field="orgName" header="Org Name" />
                            <Column field="subDomain" header="Sub Domain"
                                body={(rowData) => {
                                    return (<>
                                        <a className="ma-url ma-pointer"
                                            href={`https://${rowData.subDomain}.sendshortly.com`}
                                            target="_blank">{rowData.subDomain + '.sendshortly.com'}</a>
                                    </>)
                                }}
                            />
                            <Column field="domain" header="Domain"
                                body={(rowData) => {
                                    return (<>
                                        <a className="ma-url ma-pointer"
                                            href={rowData.domain}
                                            target="_blank">{rowData.domain}</a>
                                    </>)
                                }}
                            />

                            <Column
                                sortabl body={this.actionsTemplate} header="Actions" ></Column>
                        </DataTable>

                    </div>
                </Authorize>


                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </div>

            <Dialog
                draggable={false}
                blockScroll={true}
                closeOnEscape={true}
                className='sp-alert-box'
                dismissableMask={true}
                closable={false}
                visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                footer={() => {
                    return (<div>
                        <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editReseller: null }) }} />
                        <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                    </div>)
                }}
                onHide={() => this.setState({ warningDialog: warningDailogInit, editReseller: null })}>
                <div>


                </div>
                <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                <p className='sp-alert-msg'>{this.state.warningDialog.message}</p>
            </Dialog>

            {
                this.state.isLoading && <LoadingComponent />
            }





        </div>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(Organization)));
