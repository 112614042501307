import React, { Component } from "react";
import NavFooter from "../navFooter";
import Organization from "./organization";
import Authentication from "./../../session/index";
import { Route, Switch, Link } from "react-router-dom";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import AdminUser from "./adminManagement/adminUser";
import Roles from "./adminManagement/roles";
import PortalManage from "./organization/portalManage";
import { connect } from "react-redux";

import RegistrationFee from "./feeManagement/registration_fee";
import PackagesFee from "./feeManagement/packagesFee";
import Profiles from "./profiles/profiles";
import Subscription from "./subscriptions";
import ApprovedProfiles from "./profiles/approvedProfiles";
import Users from "./endUsers";

import Customers from "../co-space/customers";
import FeeDetailsList from "../co-space/feeDetails";
import TablesList from "../co-space/tables";
import BranchDetails from "../co-space/branches";
import Transactions from "../co-space/transactions";
import LandingPage from "../landingPage";
// import LandingPageNew from "./landingPage";
import Dashboard from "../co-space/dashboard/dashboard";
import Policy from "../chitfunds/policy";
import ChitsCustomers from "../chitfunds/customers";
import { APP_TARGETS } from "../../Utils/constants";
import Consultants from "../consultant/consultants";
import AdminUserPrivileges from "../consultant/adminUserPrivileges";
import QuestionBank from "../consultant/questionBank";
import SearchBank from "../consultant/questionBank/searchBank";

class Admin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
        };

        this.btnCospace = React.createRef();
        this.btnAdminManagement = React.createRef();
        this.btnQuestionBank = React.createRef();
        this.btnChits = React.createRef();
        this.btnRef1 = React.createRef();
        this.btnRef2 = React.createRef();
        this.btnRef3 = React.createRef();
        this.btnRef4 = React.createRef();


    }

    render() {
        let { path, url } = this.props?.match;
        console.log(this.props?.match, "ddd");

        const { title } = this.state;

        const { user } = this.props;
        console.log(this.props, "dddd-a");
        return (
            <NavFooter>
                <div className="min-h-screen flex relative lg:static">
                    <div id="app-sidebar-1" className="bg-bluegray-800 h-screen hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none" style={{ width: '280px' }}>
                        <div className="flex flex-column h-full">
                            <div className="overflow-y-auto mt-3">
                                <ul className="list-none p-3 m-0">
                                    {this.props.isSuper && (
                                        <li>
                                            <Link
                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                to={`${url}/resellers`}
                                            >
                                                {" "}
                                                <i className="pi pi-home mr-2"></i>
                                                <span className="font-medium hover:bg-bluegray-00 hover:text-bluegray-500">
                                                    Resellers
                                                </span>
                                                <Ripple />
                                            </Link>
                                        </li>
                                    )}


                                    {user?.user?.isAdmin && (
                                        <>
                                            {process.env.REACT_APP_TARGET == APP_TARGETS.CO_SPACE && <>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/branches`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Branches
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                            </>}
                                            <li>
                                                <StyleClass
                                                    nodeRef={this.btnAdminManagement}
                                                    selector="@next"
                                                    enterClassName="hidden"
                                                    enterActiveClassName="slidedown"
                                                    leaveToClassName="hidden"
                                                    leaveActiveClassName="slideup"
                                                >
                                                    <a
                                                        ref={this.btnAdminManagement}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                    >
                                                        <i className="pi pi-chart-line mr-2"></i>
                                                        <span className="font-medium">
                                                            Admin
                                                        </span>
                                                        <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                        <Ripple />
                                                    </a>
                                                </StyleClass>

                                                <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                    <li>
                                                        <Link
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                            to={`${url}/admin-roles`}
                                                        >
                                                            {" "}
                                                            <i className="pi pi-home mr-2"></i>
                                                            <span className="font-medium hover:text-white">
                                                                Admin Roles
                                                            </span>
                                                            <Ripple />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                            to={`${url}/admin-users`}
                                                        >
                                                            {" "}
                                                            <i className="pi pi-home mr-2"></i>
                                                            <span className="font-medium hover:text-white">
                                                                Admin Users
                                                            </span>
                                                            <Ripple />
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                            to={`${url}/admin-user-privileges`}
                                                        >
                                                            {" "}
                                                            <i className="pi pi-home mr-2"></i>
                                                            <span className="font-medium hover:text-white">
                                                                Admin Users Privileges
                                                            </span>
                                                            <Ripple />
                                                        </Link>
                                                    </li>



                                                </ul>
                                            </li>
                                            {process.env.REACT_APP_TARGET == APP_TARGETS.MATRIMONY && <>

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/end-users`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            End Users
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/portal-manage`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Portal Management
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/profiles`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Pending profiles
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/approved-profiles`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Approved Profiles
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/registration-fee`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Registration Fee
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/packages-fee`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Packages Fee
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
            transition-duration-150 transition-colors w-full"
                                                    to={`${url}/policy`}
                                                >
                                                    {" "}
                                                    <i className="pi pi-home mr-2"></i>
                                                    <span className="font-medium hover:text-white">
                                                        Policy
                                                    </span>
                                                    <Ripple />
                                                </Link>
                                            </li> */}

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/subscription`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Subscription
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>

                                            </>}

                                            {process.env.REACT_APP_TARGET == APP_TARGETS.CO_SPACE && <>
                                                <li>

                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/dashboard`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-users mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Dashboard
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/customers`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-users mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Customers
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/tables`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-table mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Tables
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>


                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/fee-management`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-credit-card mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Fee Management
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/transactions`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Transactions
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                            </>
                                            }


                                            {process.env.REACT_APP_TARGET == APP_TARGETS.CONSULTANT && <>
                                                <li>

                                                    <li>
                                                        <StyleClass
                                                            nodeRef={this.btnQuestionBank}
                                                            selector="@next"
                                                            enterClassName="hidden"
                                                            enterActiveClassName="slidedown"
                                                            leaveToClassName="hidden"
                                                            leaveActiveClassName="slideup"
                                                        >
                                                            <a
                                                                ref={this.btnQuestionBank}
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                            >
                                                                <i className="pi pi-chart-line mr-2"></i>
                                                                <span className="font-medium">
                                                                    Question Bank
                                                                </span>
                                                                <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                                <Ripple />
                                                            </a>
                                                        </StyleClass>

                                                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/search-bank`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Search
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                                    to={`${url}/question-bank`}
                                                                >
                                                                    {" "}
                                                                    <i className="pi pi-home mr-2"></i>
                                                                    <span className="font-medium hover:text-white">
                                                                        Questions
                                                                    </span>
                                                                    <Ripple />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/c-dashboard`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-users mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Dashboard
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/consultants`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-users mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Consultants
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/content-writer`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-table mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Content Writer
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>


                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/document-prepare`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-credit-card mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Document Prepare
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/document-editings`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Document Editings
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/document-editings`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Document Editings
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/document-verification`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Document Verifications
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/preperations`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Preparations
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/marketing`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Marketing
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/interviews`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Interviews
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/paper-work`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-sitemap mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Paper work
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li> */}
                                            </>
                                            }
                                            {process.env.REACT_APP_TARGET == APP_TARGETS.CHITFUNDS && <>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/policy`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-users mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Policy
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
            transition-duration-150 transition-colors w-full"
                                                        to={`${url}/chits-customers`}
                                                    >
                                                        {" "}
                                                        <i className="pi pi-users mr-2"></i>
                                                        <span className="font-medium hover:text-white">
                                                            Customers
                                                        </span>
                                                        <Ripple />
                                                    </Link>
                                                </li>
                                            </>}


                                            {/* <li>
                                                <StyleClass
                                                    nodeRef={this.btnRef1}
                                                    selector="@next"
                                                    enterClassName="hidden"
                                                    enterActiveClassName="slidedown"
                                                    leaveToClassName="hidden"
                                                    leaveActiveClassName="slideup"
                                                >
                                                    <a
                                                        ref={this.btnRef1}
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-white-50
                transition-duration-150 transition-colors w-full"
                                                    >
                                                        <i className="pi pi-chart-line mr-2"></i>
                                                        <span className="font-medium">
                                                            Reports
                                                        </span>
                                                        <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                        <Ripple />
                                                    </a>
                                                </StyleClass>
                                                <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                    <li>
                                                        <StyleClass
                                                            nodeRef={
                                                                this.btnRef2
                                                            }
                                                            selector="@next"
                                                            enterClassName="hidden"
                                                            enterActiveClassName="slidedown"
                                                            leaveToClassName="hidden"
                                                            leaveActiveClassName="slideup"
                                                        >
                                                            <a
                                                                ref={
                                                                    this.btnRef2
                                                                }
                                                                className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                    transition-duration-150 transition-colors w-full"
                                                            >
                                                                <i className="pi pi-chart-line mr-2"></i>
                                                                <span className="font-medium">
                                                                    Revenue
                                                                </span>
                                                                <i className="pi pi-chevron-down ml-auto mr-1"></i>
                                                                <Ripple />
                                                            </a>
                                                        </StyleClass>
                                                        <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                                                            <li>
                                                                <a
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                            transition-duration-150 transition-colors w-full"
                                                                >
                                                                    <i className="pi pi-table mr-2"></i>
                                                                    <span className="font-medium">
                                                                        View
                                                                    </span>
                                                                    <Ripple />
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                            transition-duration-150 transition-colors w-full"
                                                                >
                                                                    <i className="pi pi-search mr-2"></i>
                                                                    <span className="font-medium">
                                                                        Search
                                                                    </span>
                                                                    <Ripple />
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                    transition-duration-150 transition-colors w-full"
                                                        >
                                                            <i className="pi pi-chart-line mr-2"></i>
                                                            <span className="font-medium">
                                                                Expenses
                                                            </span>
                                                            <Ripple />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                >
                                                    <i className="pi pi-users mr-2"></i>
                                                    <span className="font-medium">
                                                        Team
                                                    </span>
                                                    <Ripple />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                >
                                                    <i className="pi pi-comments mr-2"></i>
                                                    <span className="font-medium">
                                                        Messages
                                                    </span>
                                                    <span
                                                        className="inline-flex align-items-center justify-content-center ml-auto bg-yellow-500 text-bluegray-900 border-circle"
                                                        style={{
                                                            minWidth: "1.5rem",
                                                            height: "1.5rem",
                                                        }}
                                                    >
                                                        3
                                                    </span>
                                                    <Ripple />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                >
                                                    <i className="pi pi-calendar mr-2"></i>
                                                    <span className="font-medium">
                                                        Calendar
                                                    </span>
                                                    <Ripple />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                >
                                                    <i className="pi pi-cog mr-2"></i>
                                                    <span className="font-medium">
                                                        Settings
                                                    </span>
                                                    <Ripple />
                                                </a>
                                            </li> */}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="min-h-screen flex flex-column relative flex-auto">
                        <div
                            className="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border"
                            style={{ height: "60px" }}
                        >
                            <div className="flex">
                                <StyleClass
                                    nodeRef={this.btnRef4}
                                    selector="#app-sidebar-1"
                                    enterClassName="hidden"
                                    enterActiveClassName="fadeinleft"
                                    leaveToClassName="hidden"
                                    leaveActiveClassName="fadeoutleft"
                                    hideOnOutsideClick
                                >
                                    <a
                                        ref={this.btnRef4}
                                        className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
                                    >
                                        <i className="pi pi-bars text-4xl"></i>
                                        <Ripple />
                                    </a>
                                </StyleClass>

                                <span className="p-input-icon-left font-bold">
                                    {title}
                                </span>
                            </div>
                            {/* <StyleClass nodeRef={this.btnRef5} selector="@next" enterClassName="hidden" enterActiveClassName="fadein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                <a ref={this.btnRef5} className="p-ripple cursor-pointer block lg:hidden text-700">
                                    <i className="pi pi-ellipsis-v text-2xl"></i>
                                    <Ripple />
                                </a>
                            </StyleClass>
                            <ul className="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row
    surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
                                <li>
                                    <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full">
                                        <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                        <span className="block lg:hidden font-medium">Inbox</span>
                                        <Ripple />
                                    </a>
                                </li>
                                <li>
                                    <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full">
                                        <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge"><Badge severity="danger" /></i>
                                        <span className="block lg:hidden font-medium">Notifications</span>
                                        <Ripple />
                                    </a>
                                </li>
                                <li className="border-top-1 surface-border lg:border-top-none">
                                    <a className="p-ripple flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer
            transition-duration-150 transition-colors w-full">
                                        <img src="assets/images/blocks/avatars/circle/avatar-f-1.png" alt="avatar-f-1" className="mr-3 lg:mr-0" style={{ width: '32px', height: '32px' }} />
                                        <div className="block lg:hidden">
                                            <div className="text-900 font-medium">Josephine Lillard</div>
                                            <span className="text-600 font-medium text-sm">Marketing Specialist</span>
                                        </div>
                                        <Ripple />
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                        <div className="p-5 flex flex-column flex-auto">
                            <div className="border-2 border-dashed surface-border border-round surface-section flex-auto">
                                <Switch>
                                    <Route exact path={path}>
                                        <h3>Please select a topic.</h3>
                                    </Route>
                                    <Route path={`${path}/resellers`}>
                                        <Organization
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Organization",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/admin-users`}>
                                        <AdminUser
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin Users",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/admin-user-privileges`}>
                                        <AdminUserPrivileges
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin User Privileges",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/end-users`}>
                                        <Users
                                            setTitle={() => {
                                                this.setState({
                                                    title: "End Users",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/admin-roles`}>
                                        <Roles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin Roles",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/portal-manage`}>
                                        <PortalManage
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Portal Managemenet",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/registration-fee`}>
                                        <RegistrationFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Registration Fee",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/packages-fee`}>
                                        <PackagesFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Packages Fee",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/profiles`}>
                                        <Profiles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "profiles",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/approved-profiles`}>
                                        <ApprovedProfiles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "approved profiles",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/subscription`}>
                                        <Subscription
                                            setTitle={() => {
                                                this.setState({
                                                    title: "subscriptions",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/customers`}>
                                        <Customers
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Customers",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/fee-management`}>
                                        <FeeDetailsList
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Fee Management",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/tables`}>
                                        <TablesList
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Tables",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/branches`}>
                                        <BranchDetails
                                            setTitle={() => {
                                                this.setState({
                                                    title: "branches",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/transactions`}>
                                        <Transactions
                                            setTitle={() => {
                                                this.setState({
                                                    title: "transactions",
                                                });

                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/dashboard`}>
                                        <Dashboard
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Dashboard",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/policy`}>
                                        <Policy
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Policy",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/chits-customers`}>
                                        <ChitsCustomers
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Chits Customers",
                                                });
                                            }}
                                        />
                                    </Route>

                                    {/* consultant routes started */}

                                    <Route path={`${path}/consultants`}>
                                        <Consultants
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Consultants",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/search-bank`}>
                                        <SearchBank
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Search Bank",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/question-bank`}>
                                        <QuestionBank
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Question Bank",
                                                });
                                            }}
                                        />
                                    </Route>
                                    {/* <Route path={`${path}/registration-fee`}>
                                        <RegistrationFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Registration Fee",
                                                });
                                            }}
                                        />
                                    </Route> */}
                                    {/* <Route path={`${path}/users`}>
                                        <p>users</p>
                                    </Route> */}
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    isSuper: state?.orgData?.isSuper,
    isLoading: state?.orgData?.isLoading,
});

export default connect(mapStateToProps, {})(Authentication(Admin));
