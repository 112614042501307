import BlockViewer from "../blockviewer/BlockViewer";
import { Button } from "primereact/button";
import React, { Component } from 'react';

class ActionPanelNew extends Component {
    render() {
        return (
            <>
                <BlockViewer
                    containerClassName="px-4 py-8 md:px-6 lg:px-8 flex justify-content-center align-items-center"
                >
                    <div className="surface-card border-round flex shadow-2">
                        <div className="bg-blue-50 flex align-items-center justify-content-center py-3 px-5">
                            <img
                                src="assets/images/blocks/illustration/live-collaboration.svg"
                                alt="Image"
                                className="mx-auto block mb-4 w-full"
                            />
                        </div>
                        <div className="py-3 px-5 flex flex-column align-items-start">
                            <div className="text-900 font-medium mb-3 text-xl">
                                Are You Sure ?
                            </div>
                            <p className="mt-0 mb-4 p-0 line-height-3">
                                Click on "Proceed" button to continue order process this item
                            </p>
                            <Button label="Proceed" className="mt-auto" onClick={this.props.onProceed}/>
                        </div>
                    </div>
                </BlockViewer>
            </>
        );
    }
}

export default ActionPanelNew;
    

