import BlockViewer from "../blockviewer/BlockViewer";
import React, { Component } from "react";
import { Button } from "primereact/button";

class ContainerNew extends Component {
    render() {
        return (
            <>
                <div className="block-category-title">Cooking Process</div>

                <BlockViewer header="Ingredients and Cooking process">
                    <div className="p-4 md:p-6 lg:p-8">
                        <div
                            className="border-2 border-dashed surface-border border-round surface-card"
                            style={{ minHeight: "20rem" }}
                        >
                            Biryani in India doesn't just mean biryani. There
                            are variations across the length and breadth of the
                            country. There is Hyderabadi biryani (which is what
                            I'm sharing today) where the biryani has a lot of
                            gravy or masala and is cooked slowly with rice in a
                            sealed pot. Then there is the Muslim wedding biryani
                            which actually has lesser masala, but packed with
                            flavour mostly from whole spices; the Kerala
                            biryani, donne biryani from Karnataka and so many
                            more.Chicken: I only like to use thighs and
                            drumsticks for chicken biryani because they remain
                            juicy and don't overcook through the long cooking
                            time. For me, chicken breast is a big no no as it
                            will dry out and will just be rubbery. Yogurt: Greek
                            yogurt, plain yogurt or homemade curd/dahi works
                            well. Acts as a tenderiser and add some tanginess to
                            the masala Fried Onions: Also called birista, I've
                            outlined the process to make fried onions at home
                            below. But these days, packages fried onions are
                            easily available and are more convenient option. To
                            store a large amount of fried onions, place them in
                            an airtight container and freeze them for later
                            Chicken: I only like to use thighs and drumsticks
                            for chicken biryani because they remain juicy and
                            don't overcook through the long cooking time. For
                            me, chicken breast is a big no no as it will dry out
                            and will just be rubbery. Yogurt: Greek yogurt,
                            plain yogurt or homemade curd/dahi works well. Acts
                            as a tenderiser and add some tanginess to the masala
                            Fried Onions: Also called birista, I've outlined the
                            process to make fried onions at home below. But
                            these days, packages fried onions are easily
                            available and are more convenient option. To store a
                            large amount of fried onions, place them in an
                            airtight container and freeze them for later
                            Chicken: I only like to use thighs and drumsticks
                            for chicken biryani because they remain juicy and
                            don't overcook through the long cooking time. For
                            me, chicken breast is a big no no as it will dry out
                            and will just be rubbery. Yogurt: Greek yogurt,
                            plain yogurt or homemade curd/dahi works well. Acts
                            as a tenderiser and add some tanginess to the masala
                            Fried Onions: Also called birista, I've outlined the
                            process to make fried onions at home below. But
                            these days, packages fried onions are easily
                            available and are more convenient option. To store a
                            large amount of fried onions, place them in an
                            airtight container and freeze them for later
                        </div>
                        <br/>
                        <Button label="Close" onClick={this.props.onProcessClose}></Button>

                    </div>
                    
                </BlockViewer>
            </>
        );
    }
}

export default ContainerNew;
