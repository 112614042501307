import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import BlockViewer from '../blockviewer/BlockViewer';

import React, { Component } from 'react';

class DialogBlockNew extends Component {
    constructor(props){
        super(props);
        this.state={
            visible:true
        }
    }
    render() {
        return (
            <>
             
    
                <BlockViewer  >
                    <Dialog visible={true} onHide={this.props.onHide} modal breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }} closable={false} showHeader={false} footer={<div className=" border-top-1 surface-border pt-3 flex">
                        <Button icon="pi pi-times" onClick={this.props.onHide} label="Cancel" className="p-button-outlined w-6 mr-2" />
                        <Button icon="pi pi-check" onClick={this.props.onHide} label="Proceed" className="w-6 ml-2" />
                    </div >}>
                        <div className="flex flex-column align-items-center my-4">
                            <span className="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style={{ width: '64px', height: '64px' }}>
                                <i className="pi pi-check text-5xl"></i>
                            </span>
                            <div className="font-medium text-2xl text-900">Welcome to the Restaurent</div>
                        </div>
                        <p className="line-height-3 p-0 m-0">
                            Sagittis eu volutpat odio facilisis mauris sit amet. Sed velit dignissim sodales ut eu sem integer.
                            Sed risus pretium quam vulputate. At tellus at urna condimentum mattis pellentesque.
                        </p>
                    </Dialog>
                </BlockViewer>
    
    
            </>
        )
    }
}

export default DialogBlockNew;



