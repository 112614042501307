import React, { Component } from "react";
import NavFooter from "../navFooter";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./styles.scss";
import { Accordion, AccordionTab } from "primereact/accordion";
import Cospace from "./cospace";
import { APP_TARGETS } from "../../Utils/constants";
import Matrimony from "./matrimony";
import Consultant from "./consultant";

class LandingPage extends Component {
    render() {
        return (
            <>
                {process.env.REACT_APP_TARGET == APP_TARGETS.CO_SPACE && (
                    <Cospace />
                )}
                {process.env.REACT_APP_TARGET == APP_TARGETS.MATRIMONY && (
                    <Matrimony />
                )}
                {process.env.REACT_APP_TARGET == APP_TARGETS.CONSULTANT && (
                    <Consultant />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});
export default connect(mapStateToProps, {})(withRouter(LandingPage));
