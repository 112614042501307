import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Tag } from 'primereact/tag';
import { TabMenu } from 'primereact/tabmenu';
import { StyleClass } from 'primereact/styleclass';
import { Ripple } from 'primereact/ripple';
import BlockViewer from '../blockviewer/BlockViewer';
import React, { Component } from 'react';
import DialogBlockNew from './dialogNew';

class DetailScreenNew extends Component {
    constructor(props){
        super(props);
        this.state={
isShowDialog:false,
isShowDetails:true
        }
    }

    onClick = () => {
        this.setState({
            isShowDialog:true,
// isShowDetails:false
        })
    }

    onHide = () => {
        this.setState({
            isShowDialog:false
        })
    }
    render() {
        return (
            <>
                <BlockViewer header="Applicant Detail" >
                    <div className="surface-ground">
                     
                    
                        <div className="px-6 py-5">
                            <div className="surface-card p-4 shadow-2 border-round">
                                <div className="font-medium text-3xl text-900 mb-3">Contact Details</div>
                                <ul className="list-none p-0 m-0 border-top-1 border-300">
                                    <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                                        <div className="text-500 w-full md:w-2 font-medium">Name</div>
                                        <div className="text-900 w-full md:w-10">Swagath Restaurent</div>
                                    </li>
                                    <li className="flex align-items-center py-3 px-2 flex-wrap">
                                        <div className="text-500 w-full md:w-2 font-medium">Address</div>
                                        <div className="text-900 w-full md:w-10 line-height-3">Ngo's colony, road no.8, opposite to kumari theatre, nandhyala</div>
                                    </li>
                                    <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                                        <div className="text-500 w-full md:w-2 font-medium">Phone</div>
                                        <div className="text-900 w-full md:w-10">8888888888</div>
                                    </li>
                                    <li className="flex align-items-center py-3 px-2 flex-wrap">
                                        <div className="text-500 w-full md:w-2 font-medium">Gmail</div>
                                        <div className="text-900 w-full md:w-10">swagathrestaurent@gmail.com</div>
                                        
                                    </li >
                                    <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                                        <div className="text-500 w-full md:w-2 font-medium">Branches</div>
                                        <div className="text-900 w-full md:w-10">
                                            <div className="grid mt-0 mr-0">
                                                <div className="col-12 md:col-6">
                                                    <div className="p-3 border-1 border-300 border-round surface-0">
                                                        <div className="text-900 mb-2">
                                                            <i className="pi pi-github mr-2"></i>
                                                            <span className="font-medium">Ngo's colony</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <div className="p-3 border-1 border-300 border-round surface-0">
                                                        <div className="text-900 mb-2">
                                                            <i className="pi pi-github mr-2"></i>
                                                            <span className="font-medium">New Bustastand</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <div className="p-3 border-1 border-300 border-round surface-0">
                                                        <div className="text-900 mb-2">
                                                            <i className="pi pi-github mr-2"></i>
                                                            <span className="font-medium">Ganesh colony</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <div className="p-3 border-1 border-300 border-round surface-0">
                                                        <div className="text-900 mb-2">
                                                            <i className="pi pi-github mr-2"></i>
                                                            <span className="font-medium" onClick={() =>this.onClick()}>Suraj center</span>
                                                        </div>
                                                    </div>
                                                </div>
    
                                            </div>
                                        </div>
                                    </li>
                                </ul ><br/>
                    <Button label='Close' onClick={this.props.onCloseContact}></Button>

                            </div >

                        </div >
                      
                       
                    </div>

                </BlockViewer>

                {this.state.isShowDialog && <DialogBlockNew onHide = {
                    this.onHide
                }></DialogBlockNew>}
            </>
        )
    }
}

export default DetailScreenNew;


   

    

    

    

