export const Specialization = [{ key: 'cardiologist', value: "Cardiologist" },
{ key: 'neurologist', value: "Neurologist" },
{ key: 'gastroenterologist', value: "Gastroenterologist" },
{ key: 'orthopedic', value: "Orthopedic" },
{ key: 'pulmonologist', value: "Pulmonologist" },
{ key: 'oncologist', value: "Oncologist" },
{ key: 'ent', value: "Ent Specialist" },
{ key: 'urologist', value: "Urologist" },
{ key: 'general', value: "General Physician" },
]


export const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}


export const BookingStatus = {
    Booked: 1,
    Visited: 2,
    Cancelled: 3,
}


export const S3_UPLOAD_CONFIG = {
    bucketName: "sendshortlyemailattachments",
    dirName: "uploads/" /* optional */,
    region: "ap-south-1",
    accessKeyId: "AKIAWLDIXTISNJCUYZSY",
    secretAccessKey: "dPmQoE4BfQr6UFQGkouMeDGwv22HrdWVmzY5xBWT",
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
};


export const PackagesCount = [
    { credits: "25", id: "pack_25" },
    { credits: "50", id: "pack_50" },
    { credits: "75", id: "pack_75" },
    { credits: "100", id: "pack_100" },

];

export const typeOptions = [
    { type: "Package", id: "Package" },
    { type: "Subscription", id: "Subscription" },


];




export const FeeDays = [
    { days: "1 day", id: "1_day" },
    { days: "3 days", id: "3_days" },
    { days: "7 days", id: "7_days" },
    { days: "15 days", id: "15_days" },
    { days: "30 days", id: "30_days" },
    { days: "2 months", id: "2_months" },
    { days: "3 months", id: "3_months" },
    { days: "6 months", id: "6_months" },
    { days: "1 year", id: "1_year" },

];

export const PaymentModes = [
    { type: "Online", id: "online" },
    { type: "Offline", id: "offline" },
];

export const Status = [
    { type: "Pending", id: "pending" },
    { type: "Paid", id: "paid" },
];

export const PolicyTypes = [
    { type: "Type 1- Fixed (start 5k- lift 6k)", id: "type1" },
    { type: "type2", id: "type2" },
];

export const Months = [
    { name: "1st Month", id: "1stMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

]



export const APP_TARGETS = {
    MATRIMONY: 'matrimony',
    CO_SPACE: 'cospace',
    CHITFUNDS: 'chitfunds',
    CONSULTANT: 'consultant'
}




export const CONSULTANT_STATUS = {
    CREATED: 'CREATED',
    CONTENT_WRITING: 'CONTENT_WRITING',
    DOCUMENT_PREPERATING: 'DOCUMENT_PREPERATING',
    DOCUMENT_EDITING: 'DOCUMENT_EDITING',
    TECH_PREPARING: 'TECH_PREPARING',
    DOCUMENT_VERIYING: 'DOCUMENT_VERIFYiNG',
    MARKETING: 'MARKETING'
}
