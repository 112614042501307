import React, { Component } from "react";
import NavFooter from "../navFooter";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./styles.scss";
import { Accordion, AccordionTab } from "primereact/accordion";

class LandingPage extends Component {
    render() {
        return (
            <NavFooter>
                <div className="bg-white px-4 md:px-6 lg:px-8 itro-card">
                    <div className="flex flex-wrap">
                        <div className="w-12 lg:w-6 intro-title-card">
                            <h1 className="text-8xl font-bold">
                                Welcome to {this.props.orgName}
                            </h1>

                            <p className="text-2xl  mt-0 mb-5">
                                A Consultant company.
                            </p>
                        </div>
                        <div className="w-full h-full lg:w-6 text-center lg:text-center overflow-hidden pt-8">
                            <img
                                src="assets/images/blocks/hero/rings-2.png"
                                alt="Image"
                                className="w-full lg:w-auto pt-6 h-full"
                            />
                        </div>
                    </div>
                </div>

            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});
export default connect(mapStateToProps, {})(withRouter(LandingPage));
