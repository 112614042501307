import React, { Component } from "react";
import BlockViewer from "../blockviewer/BlockViewer";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

class SidebarLayoutdummy extends Component {
    constructor(props) {
        super(props);
        this.state = {
        title: "",

        }
    }
    render() {
        let { path, url } = this.props?.match;
        console.log(this.props?.match, "ddd");

        const { title } = this.state;

        const { user } = this.props;
        return (
            <>
                <div className="block-category-title">Sidebar Layouts</div>

                <div className="min-h-screen flex flex-column surface-ground">
                    <div className="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static">
                        <img
                            src="assets/images/blocks/logos/hyper-700.svg"
                            alt="Image"
                            height="40"
                            className="mr-0 lg:mr-6"
                        />

                        <ul className="list-none p-3 lg:p-0 m-0 lg:align-items-center select-none flex-column lg:flex-row absolute hidden lg:flex lg:static surface-overlay right-0 top-100 z-1 shadow-2 lg:shadow-none">
                            <li className="lg:mr-1">
                                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:border-circle">
                                    <i className="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                    <span className="block lg:hidden font-medium">
                                        Inbox
                                    </span>
                                    <Ripple />
                                </a>
                            </li>
                            <li className="lg:mr-1">
                                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:border-circle">
                                    <i className="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0 p-overlay-badge">
                                        <Badge severity="danger" />
                                    </i>
                                    <span className="block lg:hidden font-medium">
                                        Notifications
                                    </span>
                                    <Ripple />
                                </a>
                            </li>
                            <li className="border-top-1 surface-border lg:border-top-none lg:mt-0 mt-2 lg:pt-0 pt-2">
                                <a className="p-ripple flex p-3 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:border-circle">
                                    <img
                                        src="assets/images/blocks/avatars/circle/avatar-f-1.png"
                                        className="mr-3 lg:mr-0"
                                        style={{
                                            width: "25px",
                                            height: "25px",
                                        }}
                                    />
                                    <div className="block lg:hidden">
                                        <div className="text-900 font-medium">
                                            Josephine Lillard
                                        </div>
                                        <span className="text-600 font-medium text-sm">
                                            Marketing Specialist
                                        </span>
                                    </div>
                                    <Ripple />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <ul className="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
                        <li className="pr-3">
                            <a className="cursor-pointer">
                                <i className="pi pi-home text-600"></i>
                            </a>
                        </li>
                        <li className="px-2">
                            <i className="pi pi-angle-right text-600"></i>
                        </li>
                        <li className="px-2">
                            <a className="cursor-pointer text-600 white-space-nowrap">
                                Favorites
                            </a>
                        </li>
                        <li className="px-2">
                            <i className="pi pi-angle-right text-600"></i>
                        </li>
                        <li className="px-2">
                            <a className="cursor-pointer text-purple-500 white-space-nowrap">
                                Dashboard
                            </a>
                        </li>
                    </ul>



                    <div className="flex relative lg:static p-5 flex-auto">
                        <div
                            id="app-sidebar-12"
                            className="surface-card hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none shadow-2"
                            style={{ width: "280px", borderRadius: "12px" }}
                        >
                            <div className="flex flex-column h-full">
                                <div className="overflow-y-auto">
                                    <div className="p-3">
                                        <div className="p-input-icon-left w-full">
                                            <i className="pi pi-search"></i>
                                            <InputText
                                                className="border-1 surface-border w-full"
                                                placeholder="Search"
                                                style={{ borderRadius: "30px" }}
                                            />
                                        </div>
                                    </div>
                                    <ul className="list-none px-3 pb-3 pt-0 m-0">
                                        <li>
                                            <ul className="list-none p-0 m-0 overflow-hidden">
                                                {this.props.isSuper && (
                                                <li>
                                                    <Link
                                                        className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                        to={`${url}/organization`}
                                                    >
                                                        <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100  transition-duration-150 transition-colors text-purple-500 border-purple-500 border-right-2">
                                                            <i className="pi pi-home mr-2"></i>
                                                            <span className="font-medium">
                                                                Organizations
                                                            </span>
                                                            <Ripple />
                                                        </a>
                                                    </Link>
                                                </li>

                                                )}

                                                {user?.user?.isAdmin && (
                                                <>
                                                 <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/admin-users`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 bg-purple-50 transition-duration-150 transition-colors text-purple-500 border-purple-500 border-right-2">
                                                        <i className="pi pi-home mr-2"></i>
                                                        <span className="font-medium">
                                                            Admin Users
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>
                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/end-users`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            End Users
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>

                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/admin-roles`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            Admin Roles
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>

                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/portal-manage`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            Portal Management
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>

                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/profiles`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            Pending Profiles
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>

                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/approved-profiles`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            Approved Profiles
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>
                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/registration-fee`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            Rigistration Fee
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>
                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/packages-fee`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            Package Fee
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>
                                                <li>
                                                <Link
                                                    className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
                transition-duration-150 transition-colors w-full"
                                                    to={`${url}/subscription`}
                                                >
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-bookmark mr-2"></i>
                                                        <span className="font-medium">
                                                            Subscription
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                    </Link>
                                                </li>
                                                </>
                                                )}

                                               
                                            </ul>
                                        </li>
                                    </ul>

                                    <ul className="list-none p-3 m-0">
                                        <li>
                                            <div className="p-3 flex align-items-center justify-content-between text-600">
                                                <span className="font-medium text-sm text-900">
                                                    APPLICATION
                                                </span>
                                                <Ripple />
                                            </div>
                                            <ul className="list-none p-0 m-0 overflow-hidden">
                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-folder mr-2"></i>
                                                        <span className="font-medium">
                                                            Projects
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-chart-bar mr-2"></i>
                                                        <span className="font-medium">
                                                            Performance
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a className="p-ripple flex align-items-center cursor-pointer p-3 text-700 hover:surface-100 transition-duration-150 transition-colors border-right-2 border-transparent">
                                                        <i className="pi pi-cog mr-2"></i>
                                                        <span className="font-medium">
                                                            Settings
                                                        </span>
                                                        <Ripple />
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className="mt-auto border-top-1 surface-border p-3 flex justify-content-between">
                                    <a
                                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center border-2 surface-border hover:surface-100 text-600 transition-colors transition-duration-150 border-circle"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <i className="pi pi-file text-xl"></i>
                                        <Ripple />
                                    </a>
                                    <a
                                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center border-2 surface-border hover:surface-100 text-600 transition-colors transition-duration-150 border-circle"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <i className="pi pi-star text-xl"></i>
                                        <Ripple />
                                    </a>
                                    <a
                                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center border-2 surface-border hover:surface-100 text-600 transition-colors transition-duration-150 border-circle"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <i className="pi pi-cog text-xl"></i>
                                        <Ripple />
                                    </a>
                                    <a
                                        className="p-ripple cursor-pointer inline-flex align-items-center justify-content-center bg-pink-500 text-white border-2 border-pink-600 hover:bg-pink-600 text-600 transition-colors transition-duration-150 border-circle"
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <i className="pi pi-sign-out text-xl"></i>
                                        <Ripple />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-column flex-auto">
                            <div className="border-2 border-dashed surface-border border-round surface-section flex-auto lg:ml-5"></div>
                        </div>
                    </div>
                </div>
                <div className="min-h-screen flex flex-column relative flex-auto">
                        <div
                            className="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border"
                            style={{ height: "60px" }}
                        >
                            <div className="flex">
                                <StyleClass
                                    nodeRef={this.btnRef4}
                                    selector="#app-sidebar-1"
                                    enterClassName="hidden"
                                    enterActiveClassName="fadeinleft"
                                    leaveToClassName="hidden"
                                    leaveActiveClassName="fadeoutleft"
                                    hideOnOutsideClick
                                >
                                    <a
                                        ref={this.btnRef4}
                                        className="p-ripple cursor-pointer block lg:hidden text-700 mr-3"
                                    >
                                        <i className="pi pi-bars text-4xl"></i>
                                        <Ripple />
                                    </a>
                                </StyleClass>

                                <span className="p-input-icon-left font-bold">
                                    {title}
                                </span>
                            </div>
                          
                        </div>
                        <div className="p-5 flex flex-column flex-auto">
                            <div className="border-2 border-dashed surface-border border-round surface-section flex-auto">
                                <Switch>
                                    <Route exact path={path}>
                                        <h3>Please select a topic.</h3>
                                    </Route>
                                    <Route path={`${path}/organization`}>
                                        <Organization
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Organization",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/admin-users`}>
                                        <AdminUser
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin Users",
                                                });
                                            }}
                                        />
                                    </Route>


                                    <Route path={`${path}/end-users`}>
                                        <Users
                                            setTitle={() => {
                                                this.setState({
                                                    title: "End Users",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/admin-roles`}>
                                        <Roles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Admin Roles",
                                                });
                                            }}
                                        />
                                    </Route>
                                    <Route path={`${path}/portal-manage`}>
                                        <PortalManage
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Portal Managemenet",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/registration-fee`}>
                                        <RegistrationFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Registration Fee",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/packages-fee`}>
                                        <PackagesFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Packages Fee",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/profiles`}>
                                        <Profiles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "profiles",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/approved-profiles`}>
                                        <ApprovedProfiles
                                            setTitle={() => {
                                                this.setState({
                                                    title: "approved profiles",
                                                });
                                            }}
                                        />
                                    </Route>

                                    <Route path={`${path}/subscription`}>
                                        <Subscription
                                            setTitle={() => {
                                                this.setState({
                                                    title: "subscriptions",
                                                });
                                            }}
                                        />
                                    </Route>

                                    {/* <Route path={`${path}/registration-fee`}>
                                        <RegistrationFee
                                            setTitle={() => {
                                                this.setState({
                                                    title: "Registration Fee",
                                                });
                                            }}
                                        />
                                    </Route> */}
                                    {/* <Route path={`${path}/users`}>
                                        <p>users</p>
                                    </Route> */}
                                </Switch>
                            </div>
                        </div>
                    </div>
            </>
        );
    }
}


// const mapStateToProps = (state) => ({
//     //user: state.user,
//     isSuper: state?.orgData?.isSuper,
//     isLoading: state?.orgData?.isLoading,
// });

// export default connect(mapStateToProps, {})(Authentication(SidebarLayoutdummy));

export default SidebarLayoutdummy;