import React, { Component } from 'react'
import { InputText } from "primereact/inputtext";
import ServerlessService from '../../../Service/ServerlessService';

export default class SearchBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            questions: []
        }
        this.serverlessService = new ServerlessService();
    }



    searchQuestion = (search) => {
        this.setState({
            isLoading: true,
            search: search
        });

        const url = `/consultants/questionbank/search`;
        this.serverlessService
            .post(url, {
                search
            }, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        questions: res.res.data,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });

    }




    render() {
        const { questions } = this.state;
        // console.log(questions, 'questions');
        return (
            <div className="p-4 search-bank">
                <span className="text-1000 font-medium text-2xl  mb-3">
                    Questions
                </span>
                <br />
                <InputText
                    onChange={(e) => {
                        this.searchQuestion(e.target.value)
                    }}
                    className='search-input'
                    value={this.state.search}
                />
                <span className="text-1000 font-medium text-2xl  mb-3">
                    Answers
                </span>


                <div className='answers-wrapper'>
                    {questions.map((question => <div>
                        <h3>{question.question}</h3>

                        <h4>{question.answer}</h4>

                    </div>))}


                </div>




            </div>
        )
    }
}
