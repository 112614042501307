import React from 'react';
import NavFooter from '../../navFooter';

const TermsAndConditions = () => {
  return (
    <NavFooter >
      <div id="terms-section">
        <ol>
          <li>
            <h5>Introduction</h5>
            <p>Welcome to [Your Company Name]s ("we", "our", or "us"). By accessing and using this website, you agree to be bound by these terms and conditions.</p>
          </li>

          <li>
            <h5>Intellectual Property</h5>
            <p>All content on this website, including text, graphics, logos, and images, is the property of [Your Company Name] and is protected by intellectual property laws.</p>
          </li>

          <li>
            <h5>Use of Website</h5>
            <p>You may use this website for your personal, non-commercial purposes only. You agree not to use the website for any unlawful or prohibited activities.</p>
          </li>

          <li>
            <h5>User Accounts</h5>
            <p>If applicable, users may need to create an account to access certain features or services on the website. Users are responsible for maintaining the security of their account and passwords.</p>
          </li>

          <li>
            <h5>Disclaimer of Liability</h5>
            <p>The information provided on this website is for general informational purposes only. We do not guarantee the accuracy, completeness, or timeliness of the information.</p>
          </li>

          <li>
            <h5>Third-Party Links</h5>
            <p>This website may contain links to third-party websites. We are not responsible for the content or activities of any linked sites.</p>
          </li>

          <li>
            <h5>Privacy</h5>
            <p>Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal information.</p>
          </li>

          <li>
            <h5>Termination</h5>
            <p>We reserve the right to terminate or suspend access to this website for any reason, without notice.</p>
          </li>

          <li>
            <h5>Changes to Terms and Conditions</h5>
            <p>We may update these terms and conditions at any time. Any changes will be effective immediately upon posting.</p>
          </li>

          <li>
            <h5>Governing Law</h5>
            <p>These terms and conditions are governed by the laws of [Your Jurisdiction]. Any disputes will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>
          </li>

          <li>
            <h5>Contact Us</h5>
            <p>If you have any questions or concerns about these terms and conditions, please contact us at [Your Contact Information].</p>
          </li>
        </ol>
      </div>
    </NavFooter>
  );
}

export default TermsAndConditions;

