import React, { Component } from "react";
import { Card } from "primereact/card";
import Tables from "./tables";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

class Dashboard extends Component {
    render() {
        return (
            <>
                <span className="text-1000 font-medium text-3xl mt-3 ml-5">
                    All Tables
                </span>

                <Authorize permId={PERMISSIONS.DASHBOARD_TABLES}>


                <Tables />
                </Authorize>
            </>
        );
    }
}

export default Dashboard;
