import React, { Component } from "react";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { CLOUDFRONT_ENDPOINT } from "../../../Service/config";
import moment from "moment";
import { Ripple } from "primereact/ripple";
import AddProfile from "../myaccount/addProfile";

import ContactDetails from "./contactDetails";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import NoCreditsDialog from "./noCreditsDialog";
import { Toast } from "primereact/toast";
export default class ViewProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: {},
            isShowCreditConsumeD: false,
            isEdit: false,
            isShowContactDetails: false,
            credits: 0,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }
    componentDidMount() {
        this.getProfileById();
    }

    getProfileById = () => {

        this.setState({
            isLoading: true,
            profile: {},

        });
        const url = `/mprofiles/${this.props.isVisited ? "visitedbyid" : "getprofile"
            }?id=${this.props.selectedProfileId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        profile: res.res.data,
                    });

                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    editProile = () => {
        this.setState({
            isEdit: true,
        }, this.getProfileById);
    };

    onAfterProfiledAdded = () => {
        this.setState({
            isEdit: false,
        });
    };

    onHide = () => {
        this.setState({
            isShowCreditConsumeD: false,
        });
    };

    footerContent = () => {
        return (
            <>
                <Button label="Cancel" onClick={this.onHide}></Button>
                <Button
                    label="Ok"
                    autoFocus
                    onClick={() => this.seeContactDetails()}
                ></Button>
            </>
        );
    };

    seeContactDetails = () => {

        this.setState({
            isLoading: true,
            isShowCreditConsumeD: false,
        });

        const url = `/mprofiles/contact-details?id=${this.state.profile._id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        profile: res.res.data,
                        isShowContactDetails: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        isShowNoCreditsD: true,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    checkCredits = () => {
        this.setState({
            isLoading: true,
        });

        const url = `/mprofiles/credits?profileid=${this.state.profile._id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    if (res.res.data.isVisited) {
                        this.setState((prevState) => {
                            return {
                                profile: {
                                    ...prevState.profile,
                                    ...res.res.data.profile
                                },
                                isShowContactDetails: res.res.data.isVisited
                            }
                        });
                        // this.setState({
                        //     isLoading: false,
                        //     contactDetails: res.res.data.profile,

                        //     isShowContactDetails: true,
                        // });
                    } else {
                        if (res.res.data.credits > 0) {
                            this.setState({
                                isLoading: false,
                                credits: res.res.data.credits,
                                isShowCreditConsumeD: true,
                            });
                        } else {
                            this.setState({
                                isLoading: false,
                                isShowNoCreditsD: true,
                            });
                        }
                    }
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured b",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured c",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        console.log(this.state, "state-viewprofile");
        const { profile } = this.state;
        const { isShowContactDetails } = this.state;
        return (
            <>
                <div className="layout-content w-80 shadow-2">
                    <div className="grid ">
                        <div className="col-12">
                            <Button
                                onClick={this.props.onBack}
                                icon="pi pi-arrow-left"
                                label="Back"
                            />
                        </div>
                    </div>
                    {this.state.isEdit ? (
                        <AddProfile
                            onCancel={() => {
                                this.setState({
                                    isEdit: false,
                                });
                            }}
                            editProfile={this.state.profile}
                            onAfterProfiledAdded={this.onAfterProfiledAdded}
                        />
                    ) : (
                        <div className="surface-card p-4 shadow-2 border-round">
                            <div className="grid grid-nogutter surface-border pt-2">
                                <div className="col-12 md:col-6 p-3">
                                    <div className="flex align-items-center">
                                        <img
                                            src={
                                                CLOUDFRONT_ENDPOINT +
                                                (profile?.profileUrl?.key
                                                    ? profile?.profileUrl?.key
                                                    : "")
                                            }
                                            style={{ height: "300px" }}
                                            alt="avatar-f-4"
                                            className="mr-4"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 ">
                                    <div className="text-500 pt-3 font-medium mb-2">
                                        Name
                                        {!this.props.isEditHide && (
                                            <button
                                                type="text"
                                                onClick={() => {
                                                    this.editProile();
                                                }}
                                                className="p-ripple p-link w-3rem h-3rem surface-0 hover:surface-200 border-circle shadow-2 inline-flex align-items-center justify-content-center  transition-colors transition-duration-300"
                                                style={{
                                                    top: "1rem",
                                                    right: "1rem",
                                                    float: "right",
                                                }}
                                            >
                                                <i className="pi pi-pencil text-2xl text-500"></i>
                                                <Ripple />
                                            </button>
                                        )}
                                    </div>
                                    <div className="text-900 ">
                                        {profile.firstName} {profile.lastName}
                                    </div>
                                    <div className="text-500 pt-3  font-medium mb-2">
                                        Age
                                    </div>
                                    <div className="text-900">
                                        {profile.age}
                                    </div>
                                    <div className="text-500 pt-3 font-medium mb-2">
                                        Profession
                                    </div>
                                    <div className="text-900">
                                        {profile.profession}
                                    </div>
                                    <div className="text-500 pt-3 font-medium mb-2">
                                        Salary (Month)
                                    </div>
                                    <div className="text-900">
                                        {profile.salary}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-nogutter  pt-2">
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 pt-3 font-medium mb-2">
                                        Higher Education
                                    </div>
                                    <div className="text-900">
                                        {profile.education}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Date of Birth
                                    </div>
                                    <div className="text-900">
                                        {moment(profile.dateOfBirth).format(
                                            "LLL"
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Gender
                                    </div>
                                    <div className="text-900">
                                        {profile.gender}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Language
                                    </div>
                                    <div className="text-900">
                                        {profile.motherTongue}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Religion
                                    </div>
                                    <div className="text-900">
                                        {profile.religion}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Caste
                                    </div>
                                    <div className="text-900">
                                        {profile.caste}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Sub Caste
                                    </div>
                                    <div className="text-900">
                                        {profile.subCaste}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Color
                                    </div>
                                    <div className="text-900">
                                        {profile.color}
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 p-3">
                                    <div className="text-500 font-medium mb-2">
                                        Height
                                    </div>
                                    <div className="text-900">
                                        {profile.height}
                                    </div>
                                </div>
                                {profile.additionalInfo && (
                                    <div className="col-12 md:col-6 p-3">
                                        <div className="text-500 font-medium mb-2">
                                            Additional Info
                                        </div>
                                        <div className="text-900">
                                            {profile.additionalInfo}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <h3 className=" ">Contact Details</h3>

                            {isShowContactDetails ? (
                                <>
                                    <ContactDetails
                                        profile={this.state.profile}
                                    />
                                </>
                            ) : (
                                <>
                                    <p>
                                        To see contact details, please click the
                                        below button
                                    </p>
                                    <Button
                                        label="See Details"
                                        onClick={this.checkCredits}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>

                <Dialog
                    header="Info Dialog"
                    visible={this.state.isShowCreditConsumeD}
                    style={{ width: "90%" }}
                    onHide={this.onHide}
                    footer={this.footerContent}
                >
                    <p>
                        {" "}
                        If you see this profile your one credit will be consumed
                        from your total <b>{this.state.credits}</b> credits
                    </p>
                    <p>Please confirm to see</p>
                </Dialog>
                {this.state.isShowNoCreditsD && (
                    <NoCreditsDialog
                        onHide={() => {
                            this.setState({
                                isShowNoCreditsD: false,
                            });
                        }}
                    />
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </>
        );
    }
}
