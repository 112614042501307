import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import Service from "../../../Service";
import {
    getFormFields,
    isFormValid,
    onDropDownChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
// import { trimObj } from "../../../Utils";
import PolicyFieds from "./pocily.json";
import ServerlessService from "../../../Service/ServerlessService";
import { Calendar } from "primereact/calendar";
import moment from "moment";

class AddPolicyType1 extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            PolicyFieds,
            this.props.editPolicy || {}
        );
        this.state = {
            policy: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onHide}
            />
            <Button
                label={this.props.editPolicy ? "Update" : "Add"}
                onClick={this.addOrEdit}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    addOrEdit = () => {
        // 
        let policy = this.state.policy;
        console.log(policy, "policy");

        policy.totalMonths = policy.totalMembers;
        const formStatus = isFormValid(
            PolicyFieds,
            this.formFields.formValidations,
            policy
        );
        // 

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (isCompleteFormValid) {
            if (this.props.editPolicy) {
                this.setState({
                    isLoading: true,
                });

                const url = `/chitfunds/policy/add`;
                this.serverlessService
                    .post(url, policy, true)
                    .then((res) => {

                        if (res && res?.status && res?.res?.status) {
                            this.setState(
                                {
                                    policy: {
                                        policyType: "",
                                        totalAmount: "",
                                        totalMembers: "",
                                        // totalMonths: "",
                                        montlyPay: "",
                                        payAfterLifting: "",
                                        startDate: "",
                                        endDate: "",
                                    },

                                    isLoading: false,
                                }, this.props.onpolicyAddorEdit
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occured",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occured",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });
                let policy = this.state.policy;
                let policyType = this.props?.policyType;
                const url = `/chitfunds/policy/add`;
                // 
                this.serverlessService

                    .post(url, { policy, policyType }, true)
                    .then((res) => {
                        if (res && res?.status && res?.res?.status) {
                            this.setState(
                                {
                                    policy: {
                                        policyType: "",
                                        totalAmount: "",
                                        totalMembers: "",
                                        // totalMonths: "",
                                        montlyPay: "",
                                        payAfterLifting: "",
                                        startDate: "",
                                        endDate: "",
                                    },
                                    isLoading: false,
                                }
                                // () => {
                                //     this.props.onPolicyAddOrUpdate();
                                // }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occured",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occured",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    onCalculate = (totalAmount, totalMembers, totalMonths) => {

        if (
            totalAmount.length &&
            parseInt(totalAmount) &&
            totalMembers.length &&
            parseInt(totalMembers)

            // totalMonths.length &&
            // parseInt(totalMonths)
        ) {
            let monthlyPay = parseInt(totalAmount) / parseInt(totalMembers);

            this.setState((prevState) => {
                return {
                    policy: {
                        ...prevState.policy,
                        monthlyPay: monthlyPay.toString(),
                        payAfterLifting: (monthlyPay + (monthlyPay * 20) / 100).toString(),
                    },
                    isShowMonthlyPay: true,
                    onCalculateError: ''
                };
            });
        } else {
            this.setState({
                onCalculateError:
                    "Total amount, total members and total months are required",
            });
        }
    };

    onStartDate = (e) => {

        this.setState((prevState) => {
            console.log(e.value, "e.value");
            console.log(e.value.toString(), "string timing");

            return {
                policy: {
                    ...prevState.policy,
                    startDate: e.value.toString(),
                    endDate: moment(e.value).add(600, "d").toString(),
                },
            };
        });
    };

    // const getValidTillDate = (days) => {
    //     switch (days) {
    //       case "1_days":
    //         return moment().add(1, "days");
    //         case "3_days":
    //         return moment().add(3, "days");
    //         case "7_days":
    //         return moment().add(7, "days");
    //         case "15_days":
    //         return moment().add(15, "days");
    //         case "30_days":
    //         return moment().add(30, "days");
    //         case "2_months":
    //         return moment().add(60, "days");
    //         case "3_months":
    //         return moment().add(90, "days");
    //         case "6_months":
    //         return moment().add(180, "days");
    //         case "1_year":
    //         return moment().add(360, "days");
    //       default:
    //         return new Date();
    //     }
    //   };

    render() {
        const { policy, formValidations } = this.state;
        // console.log(policy, "policy data currenttttttt ");
        return (
            <div>
                <div className="p-grid row pl-6 pr-6">
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Total Amount
                        </label>

                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "totalAmount",
                                    this,
                                    PolicyFieds,
                                    policy,
                                    formValidations,
                                    "policy",
                                    "formValidations"
                                );
                            }}
                            value={policy.totalAmount}
                        />

                        {formValidations &&
                            !formValidations.fields["totalAmount"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["totalAmount"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Total Members = Total Months
                        </label>

                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "totalMembers",
                                    this,
                                    PolicyFieds,
                                    policy,
                                    formValidations,
                                    "policy",
                                    "formValidations"
                                );
                            }}
                            value={policy.totalMembers}
                        />

                        {formValidations &&
                            !formValidations.fields["totalMembers"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["totalMembers"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    {/* <div className="col-4">
                        <label className="font-bold block mb-2">
                            Total Months
                        </label>

                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "totalMonths",
                                    this,
                                    PolicyFieds,
                                    policy,
                                    formValidations,
                                    "policy",
                                    "formValidations"
                                );
                            }}
                            value={policy.totalMonths}
                        />

                        {formValidations &&
                            !formValidations.fields["totalMonths"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["totalMonths"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div> */}

                    <div className="col-12">
                        <Button
                            label="Calculate"
                            onClick={() =>
                                this.onCalculate(
                                    policy?.totalAmount,
                                    policy?.totalMembers,
                                    //  policy?.totalMonths
                                )
                            }
                        ></Button>
                        {
                            <p className="p-error">
                                {this.state.onCalculateError}
                            </p>
                        }
                    </div>
                </div>
                {this.state.isShowMonthlyPay && (
                    <>
                        <div className="p-grid row p-6">
                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    Monthly Pay
                                </label>

                                <InputText value={policy.monthlyPay} disabled />
                            </div>

                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    Pay After Lifting
                                </label>

                                <InputText
                                    value={policy.payAfterLifting}
                                    disabled
                                />
                            </div>

                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    Start Date
                                </label>

                                <Calendar
                                    value={
                                        policy.startDate
                                            ? new Date(policy.startDate)
                                            : null
                                    }
                                    showIcon={true}
                                    placeholder="Start Date"
                                    id="startDate"
                                    className="w-full"
                                    // onChange={(e) =>
                                    //     onTextChange(
                                    //         e.value.toString(),
                                    //         "startDate",
                                    //         this,
                                    //         PolicyFieds,
                                    //         policy,
                                    //         formValidations,
                                    //         "policy",
                                    //         "formValidations"
                                    //     )
                                    // }

                                    onChange={this.onStartDate}
                                ></Calendar>

                                {formValidations &&
                                    !formValidations.fields["startDate"]
                                        .isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields[
                                                    "startDate"
                                                ].errorMsg
                                            }
                                        </p>
                                    )}
                            </div>

                            <div className="col-4">
                                <label className="font-bold block mb-2">
                                    End Date
                                </label>

                                <Calendar
                                    value={
                                        policy.endDate
                                            ? new Date(policy.endDate)
                                            : null
                                    }
                                    showIcon={true}
                                    disabled
                                    placeholder="End Date"
                                    className="w-full"
                                    id="endDate"
                                    onChange={(e) =>
                                        onTextChange(
                                            e.value.toString(),
                                            "endDate",
                                            this,
                                            PolicyFieds,
                                            policy,
                                            formValidations,
                                            "policy",
                                            "formValidations"
                                        )
                                    }
                                ></Calendar>

                                {formValidations &&
                                    !formValidations.fields["endDate"]
                                        .isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields[
                                                    "endDate"
                                                ].errorMsg
                                            }
                                        </p>
                                    )}
                            </div>
                        </div>

                        <div className="text-right">
                            <Button
                                label="Cancel"
                                className=" p-button-text hover:bg-primary "
                                onClick={this.props.onHide}
                            />
                            <Button
                                label={this.props.editPolicy ? "Update" : "Add"}
                                onClick={this.addOrEdit}
                                autoFocus
                                className=" p-button-primary "
                            />
                        </div>
                    </>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </div>
        );
    }
}

export default AddPolicyType1;
