import React, { Component } from 'react'
import ServerlessService from '../../../Service/ServerlessService';
import { BasicLazyParams } from "../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import LoadingComponent from '../../../LoadingComponent';
import moment from "moment";

export default class TableHistroy extends Component {
    constructor(props) {
        super(props);
        this.state = {

            tableHistorysData: null,

            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }


    componentDidMount() {

        // this.setState({
        //     rowDataOfTable: this.props.rowDataOfTable
        // }, this.showTableHistory);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.rowDataOfTable._id !== this.props.rowDataOfTable._id) {


            this.setState({
                rowDataOfTable: nextProps.rowDataOfTable
            }, this.showTableHistory);

        }
    }



    showTableHistory = () => {

        const { rowDataOfTable } = this.state;
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            rowDataOfTable,
            tableHistorysData: [],
        });
        const url = `/co-space/transactions/byTableName/${rowDataOfTable.name}?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    console.log(res?.res?.data);
                    this.setState({
                        isLoading: false,
                        tableHistorysData: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.showTableHistory);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.showTableHistory);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.showTableHistory);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.showTableHistory);
    };


    render() {
        const { tableHistorysData } = this.state;

        return (
            <div>
                <>
                    <span className="text-1000 font-medium text-2xl  mb-3">
                        Consultant Details
                    </span>
                    <br />
                    <div>

                        <Toast
                            ref={(el) => (this.toast = el)}
                            position="bottom-right"
                        />
                        {this.state.isLoading && (
                            <LoadingComponent />
                        )}
                    </div>
                </>

            </div>
        )
    }
}
