import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class NoCreditsDialog extends Component {
    render() {
        return (
            <Dialog
                header="Info Dialog"
                visible={true}
                style={{ width: "90%" }}
                onHide={this.props.onHide}
                closable={true}

            >
                <p> You don't have credits to see the contact details.</p>
                <p>Please purchase.</p>
                <Link to="/user-packages" className="">Purchase</Link>
            </Dialog>

        )
    }
}
