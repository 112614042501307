import React, { Component } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import Service from "../../../Service";
import LoadingComponent from "../../../LoadingComponent";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Dialog } from "primereact/dialog";

class Transactions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            transactions: null,
            filteredTransactions: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            date: null,
            dialogVisible: false,
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
        this.calendarRef = React.createRef();
    }

    getTransactions = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            transactions: [],
        });

        const url = `/co-space/transactions?limit=${lazyParams.rows}&page=${
            lazyParams.page + 1
        }&search=${globalSearch}${
            lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${
                      lazyParams.sortField
                  }`
                : ""
        }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    console.log(res?.res?.data);
                    this.setState({
                        isLoading: false,
                        transactions: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getTransactionsByDateFilter = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            filteredTransactions: [],
        });
        const createdDate = this.state.date.map((formatdate) =>
            new Date(formatdate).toISOString()
        );
        const url = `/co-space/transactions/date/${createdDate}?limit=${
            lazyParams.rows
        }&page=${lazyParams.page + 1}&search=${globalSearch}${
            lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${
                      lazyParams.sortField
                  }`
                : ""
        }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                console.log(res.res.data);
                if (res && res?.status && res?.res?.status) {
                    console.log(res?.res?.data);
                    this.setState({
                        isLoading: false,
                        filteredTransactions: res?.res?.data,
                        filteredTotalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getTransactions);
    };

    onPage = (event) => {
        this.setState(
            (prevState) => {
                return {
                    lazyParams: {
                        ...prevState.lazyParams,
                        ...event,
                    },
                };
            },
            this.getTransactions,
            this.getTransactionsByDateFilter
        );
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getTransactions);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState(
            (prevState) => {
                return {
                    lazyParams: {
                        ...prevState.lazyParams,
                        filters: event.filters,
                    },
                };
            },
            this.getTransactions,
            this.getTransactionsByDateFilter
        );
    };

    componentDidMount() {
        this.getTransactions();
    }

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-4 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.BRANCHES_DELETE}>
                        <span
                            onClick={() =>
                                this.showDeleteBranchDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Branch"
                            data-pr-position="top"
                            className="p-text"
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target=".p-text" />
                    </Authorize>
                </div>
            </div>
        );
    };

    openDialog = () => {
        this.setState({ dialogVisible: true });
    };

    closeDialog = () => {
        this.setState({ dialogVisible: false });
    };

    handleDateRangeSelection = () => {
        this.getTransactionsByDateFilter();
        this.closeDialog();
    };

    footerContent = () => {
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.deleteBranch()}
                autoFocus
                className=" p-button-primary "
            />
        </div>;
    };
    calendarFooter = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    marginTop: "10px",
                }}
            >
                <Button
                    className="p-button-success ok-button"
                    onClick={this.handleOK}
                >
                    OK
                </Button>
            </div>
        );
    };

    onReset = () => {
        this.state.date = null;
        this.getTransactions();
    };

    render() {
        const { transactions } = this.state;
        const { filteredTransactions } = this.state;
        const { date } = this.state;

        console.log(date);

        return (
            <>
                <div className="p-4" style={{ display: "grid" }}>
                    <span className="text-1000 font-large text-2xl  mb-3 bold">
                        Transactions
                    </span>
                    <div
                        className="field col-8 md:col-4"
                        style={{
                            marginLeft: "480px",
                        }}
                    >
                        <label
                            htmlFor="range"
                            style={{
                                margin: "10px",
                            }}
                        >
                            Select Date
                        </label>
                        <Button
                            style={{ marginRight: "10px" }}
                            onClick={this.openDialog}
                        >
                            Open Calendar
                        </Button>
                        <Dialog
                            visible={this.state.dialogVisible}
                            onHide={this.closeDialog}
                            modal
                            style={{ width: "300px" }} // Adjust the width as needed
                        >
                            <Calendar
                                id="range"
                                value={this.state.date}
                                onChange={(e) =>
                                    this.setState({ date: e.value })
                                }
                                selectionMode="range"
                                readOnlyInput
                                showIcon
                            />
                            <Button
                                style={{ marginTop: "10px" }}
                                onClick={this.handleDateRangeSelection}
                            >
                                Apply Date Range
                            </Button>
                        </Dialog>
                        <Button onClick={this.onReset}>Reset</Button>
                    </div>
                    <br />
                    <br />
                    {this.state.date === null ? (
                        <Authorize permId={PERMISSIONS.TRANSACTIONS_DATA}>
                            <div>
                                <DataTable
                                    value={transactions}
                                    lazy
                                    scrollable
                                    responsiveLayout="scroll"
                                    rowHover
                                    onSort={this.onSort}
                                    onPage={this.onPage}
                                    onFilter={this.onFilter}
                                    first={this.state.lazyParams.first}
                                    last={this.state.totalRecords}
                                    rows={this.state.lazyParams.rows}
                                    totalRecords={this.state.totalRecords}
                                    paginator
                                    scrollHeight="400px"
                                    paginatorLeft
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[
                                        5, 10, 25, 50, 100, 200, 300, 500,
                                    ]}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    dataKey="_id"
                                    selection={this.state.selectedCustomer}
                                    globalFilter={this.state.globalFilter}
                                    onSelectionChange={(e) =>
                                        this.setState({
                                            selectedCustomer: e.value,
                                        })
                                    }
                                    selectionMode="single"
                                >
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData?.assignTableDetails
                                                        ?.name || "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Assigned Table"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData
                                                        ?.assignCustomerDetails
                                                        ?.name || "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Assigned To"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>{rowData?.days || "--NA--"}</>
                                            );
                                        }}
                                        header="Time Period"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>{rowData?.fee || "--NA--"}</>
                                            );
                                        }}
                                        header="Amount Paid"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            // Determine the severity based on rowData.paymentMode
                                            const severity =
                                                rowData.paymentMode === "online"
                                                    ? "p-button-raised p-button-success"
                                                    : "p-button-raised p-button-secondary";

                                            return (
                                                <Button
                                                    label={
                                                        rowData.paymentMode ||
                                                        "--NA--"
                                                    }
                                                    className={severity}
                                                    disabled
                                                />
                                            );
                                        }}
                                        header="PaymentMode"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData?.paymentReferenceNumber ||
                                                        "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="ReferenceNumber"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData?.paymentGateway ||
                                                        "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Payment Gateway"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {moment(
                                                        rowData?.createdAt
                                                    ).format("MM/DD/YYYY") ||
                                                        "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Payment Date"
                                    ></Column>
                                </DataTable>

                                <Toast
                                    ref={(el) => (this.toast = el)}
                                    position="bottom-right"
                                />
                                {this.state.isLoading && <LoadingComponent />}
                            </div>
                        </Authorize>
                    ) : (
                        <Authorize permId={PERMISSIONS.TRANSACTIONS_DATA}>
                            <div>
                                <DataTable
                                    value={filteredTransactions}
                                    lazy
                                    scrollable
                                    responsiveLayout="scroll"
                                    rowHover
                                    onSort={this.onSort}
                                    onPage={this.onPage}
                                    onFilter={this.onFilter}
                                    first={this.state.lazyParams.first}
                                    last={this.state.filteredTotalRecords}
                                    rows={this.state.lazyParams.rows}
                                    totalRecords={
                                        this.state.filteredTotalRecords
                                    }
                                    paginator
                                    scrollHeight="400px"
                                    paginatorLeft
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    rowsPerPageOptions={[
                                        5, 10, 25, 50, 100, 200, 300, 500,
                                    ]}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    dataKey="_id"
                                    selection={this.state.selectedCustomer}
                                    globalFilter={this.state.globalFilter}
                                    onSelectionChange={(e) =>
                                        this.setState({
                                            selectedCustomer: e.value,
                                        })
                                    }
                                    selectionMode="single"
                                >
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData?.assignTableDetails
                                                        ?.name || "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Assigned Table"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData
                                                        ?.assignCustomerDetails
                                                        ?.name || "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Assigned To"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>{rowData?.days || "--NA--"}</>
                                            );
                                        }}
                                        header="Time Period"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>{rowData?.fee || "--NA--"}</>
                                            );
                                        }}
                                        header="Amount Paid"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            // Determine the severity based on rowData.paymentMode
                                            const severity =
                                                rowData.paymentMode === "online"
                                                    ? "p-button-raised p-button-success"
                                                    : "p-button-raised p-button-secondary";

                                            return (
                                                <Button
                                                    label={
                                                        rowData.paymentMode ||
                                                        "--NA--"
                                                    }
                                                    className={severity}
                                                    disabled
                                                />
                                            );
                                        }}
                                        header="PaymentMode"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData?.paymentReferenceNumber ||
                                                        "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="ReferenceNumber"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {rowData?.paymentGateway ||
                                                        "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Payment Gateway"
                                    ></Column>
                                    <Column
                                        body={(rowData) => {
                                            return (
                                                <>
                                                    {moment(
                                                        rowData?.createdAt
                                                    ).format("MM/DD/YYYY") ||
                                                        "--NA--"}
                                                </>
                                            );
                                        }}
                                        header="Payment Date"
                                    ></Column>
                                </DataTable>

                                <Toast
                                    ref={(el) => (this.toast = el)}
                                    position="bottom-right"
                                />
                                {this.state.isLoading && <LoadingComponent />}
                            </div>
                        </Authorize>
                    )}
                </div>
            </>
        );
    }
}

export default Transactions;
