import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { AvatarGroup } from "primereact/avatargroup";
import { Avatar } from "primereact/avatar";
import BlockViewer from "../blockviewer/BlockViewer";


import React, { Component } from 'react';

class CardHeadingNew extends Component {
    render() {
        return (
            <>
                <BlockViewer containerClassName="px-4 py-8 md:px-6 lg:px-8">
                    <div className="surface-card p-4 shadow-2 border-round">
                        <div className="text-3xl font-medium text-900 mb-3">
                            About Restaurent
                        </div>
                        <div className="font-medium text-500 mb-3">
                            Swagath restaurent details are shown below
                        </div>
                        <div
                            style={{ height: "150px" }}
                            className="border-2 border-dashed surface-border"
                        >
                            This is swagath restaurent. this restaurent is famous
                            for vegiterian food items. This is swagath restaurent.
                            this restaurent is famous for vegiterian food items.
                            This is swagath restaurent. this restaurent is famous
                            for vegiterian food items. This is swagath restaurent.
                            this restaurent is famous for vegiterian food items.
                            This is swagath restaurent. this restaurent is famous
                            for vegiterian food items. This is swagath restaurent.
                            this restaurent is famous for vegiterian food items.
                            This is swagath restaurent. this restaurent is famous
                            for vegiterian food items. This is swagath restaurent.
                            this restaurent is famous for vegiterian food items.
                            This is swagath restaurent. this restaurent is famous
                            for vegiterian food items. This is swagath restaurent.
                            this restaurent is famous for vegiterian food items.
                            This is swagath restaurent. this restaurent is famous
                            for vegiterian food items. This is swagath restaurent.
                            this restaurent is famous for vegiterian food items.
                        </div>
                    </div>
                </BlockViewer>
            </>
        );
    }
}

export default CardHeadingNew;



