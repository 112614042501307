import moment from "moment";
import React, { Component } from "react";
import { CLOUDFRONT_ENDPOINT } from "../../../Service/config";

class BasicDetails extends Component {
    render() {
        const { profile } = this.props;
        return (
            <div>
                <div className="grid grid-nogutter surface-border pt-2">
                    <div className="col-12 md:col-6 p-3">
                        <div className="flex align-items-center">
                            <img
                                src={
                                    CLOUDFRONT_ENDPOINT +
                                    (profile.profileUrl?.key
                                        ? profile.profileUrl?.key
                                        : "")
                                }
                                style={{ height: "300px" }}
                                alt="avatar-f-4"
                                className="mr-4"
                            />
                        </div>
                    </div>
                    <div className="col-12 md:col-6 ">
                        <div className="text-500 pt-3 font-medium mb-2">
                            Name
                        </div>
                        <div className="text-900 ">
                            {profile.firstName} {profile.lastName}
                        </div>
                        <div className="text-500 pt-3  font-medium mb-2">
                            Age
                        </div>
                        <div className="text-900">{profile.age}</div>
                        <div className="text-500 pt-3 font-medium mb-2">
                            Profession
                        </div>
                        <div className="text-900">{profile.profession}</div>
                        <div className="text-500 pt-3 font-medium mb-2">
                            Salary (Month)
                        </div>
                        <div className="text-900">{profile.salary}</div>
                    </div>
                </div>
                <div className="grid grid-nogutter  pt-2">
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 pt-3 font-medium mb-2">
                            Higher Education
                        </div>
                        <div className="text-900">{profile.education}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">
                            Date of Birth
                        </div>
                        <div className="text-900">
                            {moment(profile.dateOfBirth).format("LLL")}
                        </div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">Gender</div>
                        <div className="text-900">{profile.gender}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">
                            Language
                        </div>
                        <div className="text-900">{profile.language}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">
                            Religion
                        </div>
                        <div className="text-900">{profile.religion}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">Caste</div>
                        <div className="text-900">{profile.caste}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">
                            Sub Caste
                        </div>
                        <div className="text-900">{profile.subCaste}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">Caste</div>
                        <div className="text-900">{profile.color}</div>
                    </div>
                    <div className="col-12 md:col-6 p-3">
                        <div className="text-500 font-medium mb-2">
                            Sub Caste
                        </div>
                        <div className="text-900">{profile.height}</div>
                    </div>
                    {profile.additionalInfo && (
                        <div className="col-12 md:col-6 p-3">
                            <div className="text-500 font-medium mb-2">
                                Additional Info
                            </div>
                            <div className="text-900">
                                {profile.additionalInfo}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default BasicDetails;
