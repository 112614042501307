import React, { Component } from 'react'

import { getOrgData } from './../../store/actions';

import { EMAIL_SERVER_URL } from './../../Service/config';

import { connect } from 'react-redux';
import LoadingComponent from './../../LoadingComponent';

class OrgState extends Component {
    constructor(props) {
        super(props);
    }




    componentDidMount() {
        if (!this.props.assignedModules) {
            this.props.getOrgData();
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.orgName != this.props.orgName) {
            document.title = this.props.orgName;
        }


        if (prevProps.theme != this.props.theme) {
            if (this.props.theme == 'default') {
                localStorage.setItem('data-theme', null);
                document.documentElement.setAttribute('data-theme', null);
            } else {
                localStorage.setItem('data-theme', this.props.theme);
                document.documentElement.setAttribute('data-theme', this.props.theme);
            }
        }
    }


    render() {
        //console.log(this.props.orgData, 'orgData')

        return (
            <>
                {
                    this.props.isLoading &&
                    <LoadingComponent />
                }
            </>
        )
    }
}




const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
    orgData: state.orgData,
    isLoading: state?.orgData?.isLoading,
    theme: state?.orgData?.theme

});

export default connect(mapStateToProps, { getOrgData })(OrgState);

