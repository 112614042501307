import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import CreateRole from './createRole';

import './styles.scss'
import Authorizer, { PERMISSIONS, isAuthorized } from './../../../../session/authorize';
import LoadingComponent from './../../../../LoadingComponent';
import { warningDailogInit } from './../../../../Utils';
import Service from './../../../../Service';
import ServerlessService from './../../../../Service/ServerlessService';
const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    sortField: null,
    sortOrder: null,
    filters: {
        'name': { value: '', matchMode: 'contains' },
        'country.name': { value: '', matchMode: 'contains' },
        'company': { value: '', matchMode: 'contains' },
        'representative.name': { value: '', matchMode: 'contains' },
    }
}


class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: '',
            globalSearch: '',
            columns: [
                { columnName: 'Role Name' },
            ],


            roles: [],
            editRoleData: null,
            showSearch: false,
            isShowCreate: false,
            isAdd: false,
            dialogData: {},

            warningDialog: warningDailogInit,
            totalRecords: 0,
            globalSearch: '',
            lazyParams: BasicLazyParams
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    getRoles = () => {
        this.setState({
            isLoading: true
        });
        let lazyParams = this.state.lazyParams;
        let url = `/authorize/getRoles?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${this.state.globalSearch}`;
        this.serverlessService.get(url, true).then(res => {

            if (res && res.res && res.res.data) {
                this.setState({
                    roles: res.res.data.map((r, index) => {
                        r.slNo = index + 1;
                        return r;
                    }),
                    totalRecords: res.res.totalRecords,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }
        }).catch((e) => {
            this.setState({
                isLoading: false
            })
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        });

    }


    componentDidMount() {
        this.getRoles();

    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData }
        if (e.value == true) {
            data.status = 'Active'
        } else {
            data.status = 'InActive'
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: 'Are you Sure?',
                message: `You are changing this Role status ${e.value ? 'InActive' : 'Active'} to ${e.value ? 'Active' : 'InActive'}`
            },
            editRoleData: data
        });
    }



    onConfirmChange = () => {

        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true
        })
        const url = `/authorize/updateRole`;
        this.serverlessService.put(url, this.state.editRoleData, true).then((res) => {
            if (res && res.status) {
                this.setState({
                    isLoading: false,

                }, () => {
                    this.onRoleCreate(false);
                })

            } else {

                this.setState({
                    isLoading: false
                })
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.errMessage, life: 3000 });
            }


        }).catch(e => {
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            this.setState({
                isLoading: false
            });
        });

    }
    exportCSV = () => {
        this.dt.exportCSV();
    }


    editTemplate = (rowData) => {
        return (<>
            <div className="p-d-flex sp-mlr10 user-edit-t">
                <Authorizer permId={PERMISSIONS.ROLE_EDIT} >
                    <span data-pr-tooltip="Edit Role" data-pr-position="bottom" className={`sp-ml10 anal${rowData._id.substr(4)}`} onClick={() => this.showEditRoleDialog(rowData)} >
                        <i className='pi pi-pencil' />
                    </span>
                    <Tooltip className="table-li-tooltip2" autoHide={false} target={`.anal${rowData._id.substr(4)}`} />
                </Authorizer>
            </div>
        </>
        );
    }

    onPage = (event) => {
        this.setState({ lazyParams: event }, this.getRoles);
    }

    showEditRoleDialog = (editRoleData) => {

        this.setState({
            editRoleData,
            isShowCreate: true,
            isAdd: false
        });
    }

    showRoleCreateDialog = () => {

        this.setState({
            isShowCreate: true,
            editRoleData: null,
            isAdd: true
        });

    }

    hideRoleCreateDialog = () => {
        this.setState({
            isShowCreate: false,
            editRoleData: null,
            isAdd: false
        });
    }

    onRoleCreate = (isCreate, data) => {


        this.toast.show({ severity: 'success', summary: isCreate ? 'Role Created' : 'Role Updated', detail: `Role Successfully ${isCreate ? 'Created' : 'Updated'}`, life: 3000 });
        this.setState({
            isShowCreate: false,
            editRoleData: null,
            lazyParams: BasicLazyParams
        }, () => {
            this.getRoles()
        });

    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams
            }
        }, this.getRoles);
    }

    render() {



        return (
            <>
                <div className='h-screen overflow-auto p-4'>
                    <span className="text-1000 font-medium text-4xl  mb-3">
                        Admin Roles
                    </span>
                    <br />
                    {
                        this.state.isLoading && <>
                            <LoadingComponent />
                        </>
                    }

                    <Authorizer permId={PERMISSIONS.ROLE_ADD} >
                        <Button label={`Add Role`} className='p-button-primary' onClick={this.showRoleCreateDialog} />
                    </Authorizer>
                    <Authorizer permId={PERMISSIONS.ROLE_LIST} >
                        <div className="p-4 ">
                            <DataTable ref={(el) => this.dt = el}
                                value={this.state.roles}
                                dataKey="_id"
                                paginator
                                lazy
                                onPage={this.onPage}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                responsiveLayout="scroll"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                            >
                                <Column field="slNo" header="Sr. No" body={(rowData) => {
                                    return <span>
                                        {(this.state.lazyParams.page * this.state.lazyParams.rows) + rowData.slNo}
                                    </span>
                                }} />
                                <Column field="roleName" header="Role" />
                                <Column body={(rowData) => {
                                    return (
                                        <div className="sp-status">

                                            {/* <span className="sp-status-text">{rowData.status} </span> */}
                                            <Authorizer permId={PERMISSIONS.ROLE_EDIT} >
                                                <InputSwitch
                                                    onChange={(e) => { this.changeStatus(e, rowData) }}
                                                    checked={rowData.status == 'Active'} />
                                            </Authorizer>
                                        </div>
                                    )
                                }} field="status" header="Status" />
                                <Column body={this.editTemplate} header="Actions"></Column>
                            </DataTable>






                            <Toast ref={(el) => this.toast = el} position="bottom-right" />

                            {
                                this.state.isShowCreate && <CreateRole
                                    onRoleCreate={this.onRoleCreate}
                                    isAdd={this.state.isAdd}
                                    hideRoleCreateDialog={this.hideRoleCreateDialog}
                                    editRoleData={this.state.editRoleData}
                                />
                            }
                        </div>
                    </Authorizer>
                    <Dialog
                        header={<></>}
                        draggable={false}
                        closeOnEscape={true}
                        className='sp-alert-box'
                        blockScroll={true}
                        dismissableMask={true}
                        closable={false}
                        visible={this.state.warningDialog.visible} style={{ maxWidth: '22vw', minWidth: '300px' }}
                        footer={() => {
                            return (<div>
                                <Button label="No" className='p-button-outlined' onClick={() => { this.setState({ warningDialog: warningDailogInit, editRoleData: null }) }} />
                                <Button label="Yes" className='sp-m-lr10' onClick={this.onConfirmChange} />

                            </div>)
                        }}
                        onHide={() => this.setState({ warningDialog: warningDailogInit, editRoleData: null })}>
                        <div>
                            <span className="warning-icon" >
                            </span>
                        </div>
                        <p className='sp-alert-head'>{this.state.warningDialog.headerMsg}</p>
                        <p className='sp-alert-msg'>{this.state.warningDialog.message}</p>
                    </Dialog>

                </div>

            </>
        )
    }
}




const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);