import BlockViewer from "../blockviewer/BlockViewer";
import React, { Component } from 'react';

class AlertNew extends Component {
    render() {
        return (
            <>
                <BlockViewer
                    containerClassName="surface-section p-3 md:p-6 lg:p-8"
                >
                    <div className="flex align-items-start p-4 bg-green-100 border-round border-1 border-green-300">
                        <i className="pi pi-check-circle text-green-900 text-2xl mr-3"></i>
                        <div className="mr-3">
                            <div className="text-green-900 font-medium text-xl mb-2 line-height-1">
                                Success
                            </div>
                            <p className="m-0 p-0 text-green-700 line-height-3">
                              This Food item added to your favourite list. you can see this item in your favorite list now.
                            </p>
                        </div>
                        <div className="ml-auto">
                            <a
                                className="inline-flex align-items-center justify-content-center ml-auto border-circle hover:bg-green-50 no-underline cursor-pointer transition-colors transition-duration-150"
                                style={{ width: "1.5rem", height: "1.5rem" }}
                            >
                                <i className="pi pi-times text-green-900" onClick={ this.props.onCancel}></i>
                            </a>
                        </div>
                    </div>
                </BlockViewer>
    
               
            </>
        );
    }
}

export default AlertNew;

    

   

    

