import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import React, { Component } from 'react';
import ServerlessService from "../../../Service/ServerlessService";
import { classNames } from "primereact/utils";
import NavFooter from "../../navFooter";
import UserCheckout from "./userCheckout";




class UserPackages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            packages: null,
            isCheckout: false,
            currentTab: 'Package',

        }
        this.serverlessService = new ServerlessService();

    }


    onClickBuy = (item) => {
        this.setState({
            isCheckout: true,
            isShowPackages: false,
            isShowSubscriptions: false,
            selectedPackage: item,
        });
    };

    onClickPackages = () => {
        this.setState({
            currentTab: 'Package',

        }, this.getSubscriptionsAndPackages);
    };

    onClickSubscriptions = () => {
        this.setState({
            currentTab: 'Subscription',

        }, this.getSubscriptionsAndPackages);
    };

    getSubscriptionsAndPackages = () => {
        this.setState({
            isLoading: true,
        });

        const url = `/subscription/all?type=${this.state.currentTab}`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    console.log(
                        res?.res?.data,
                        "get packages and subscriptions data"
                    );


                    this.setState({
                        isLoading: false,
                        packagesAndSub: res.res?.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res?.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getSubscriptionsAndPackages();
    }

    render() {
        const { currentTab } = this.state;

        console.log(this.state.packagesAndSub, "Data of packages");
        return (
            <NavFooter>
                <>
                    {this.state.isCheckout ? (
                        <UserCheckout
                            selectedPackage={this.state.selectedPackage}
                        ></UserCheckout>
                    ) : <>
                        <ul
                            className="bg-indigo-500 p-2 m-0 list-none flex overflow-x-auto select-none"
                            style={{ borderRadius: "30px" }}
                        >
                            <li className="mr-2">
                                <a
                                    className={classNames(
                                        "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:bg-indigo-400 transition-colors transition-duration-150",
                                        {
                                            "bg-white hover:bg-white text-indigo-900":
                                                currentTab === 'Package',
                                            "text-white": currentTab !== 'Package',
                                        }
                                    )}
                                    onClick={() => this.onClickPackages()}
                                    style={{ borderRadius: "30px" }}
                                >
                                    <i className="pi pi-home mr-2"></i>
                                    <span className="font-medium">Packages</span>
                                    <Ripple />
                                </a>
                            </li>
                            <li className="mr-2">
                                <a
                                    className={classNames(
                                        "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:bg-indigo-400 transition-colors transition-duration-150",
                                        {
                                            "bg-white hover:bg-white text-indigo-900":
                                                currentTab === 'Subscription',
                                            "text-white": currentTab !== 'Subscription',
                                        }
                                    )}
                                    onClick={() => this.onClickSubscriptions()}
                                    style={{ borderRadius: "30px" }}
                                >
                                    <i className="pi pi-users mr-2"></i>
                                    <span className="font-medium">Subscriptions</span>
                                    <Ripple />
                                </a>
                            </li>
                        </ul>



                        <div className="surface-section px-4 py-8 md:px-6 lg:px-8">

                            <div className="text-900 font-bold text-6xl text-center mb-4">
                                Pricing Plans
                            </div>


                            <div className=" row mt-4">
                                {this.state.packagesAndSub?.map((item) => (


                                    <div className="mb-6 md:mb-0 mr-4">
                                        <div
                                            className="bg-bluegray-800 text-center p-3 border-3 border-green-500 border-bottom-none"
                                            style={{ borderRadius: "6px 6px 0 0" }}
                                        >

                                            <div className="text-2xl font-bold text-white mb-3">
                                                {item?.packageName}
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <span className="font-bold text-white text-5xl mr-2">
                                                    Rs.{item?.price}

                                                </span>

                                            </div>
                                        </div>
                                        <div
                                            className="bg-bluegray-900 p-4 flex flex-column flex-grow-1 border-3 border-green-500 border-top-none"
                                            style={{
                                                boxShadow:
                                                    "inset 0px 2px 8px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "0 0 6px 6px",
                                            }}
                                        >
                                            <div className="text-bluegray-400 font-bold line-height-3 mb-4">
                                                Package or Subscription full details are shown below
                                            </div>
                                            <ul className="list-none p-0 m-0 mb-5 text-bluegray-100">
                                                <li className="flex align-items-center mb-3">
                                                    <i className="pi pi-circle-on mr-2 text-sm"></i>
                                                    <span className="text-bluegray-400 font-bold line-height-3">
                                                        {item?.credits} credits
                                                    </span>
                                                </li>
                                                <li className="flex align-items-center mb-3">
                                                    <i className="pi pi-circle-on mr-2 text-sm"></i>
                                                    <span className="text-bluegray-400 font-bold line-height-3">Rs.{item?.price}</span>
                                                </li>
                                                {item?.days && <li className="flex align-items-center mb-3">
                                                    <i className="pi pi-circle-on mr-2 text-sm"></i>
                                                    <span className="text-bluegray-400 font-bold line-height-3"> {item?.days} days</span>
                                                </li>}

                                                <li className="flex align-items-center">
                                                    <i className="pi pi-circle-on mr-2 text-sm"></i>
                                                    <span className="text-bluegray-400 font-bold line-height-3"> {item?.type}</span>
                                                </li>

                                            </ul>

                                            <Button
                                                className="p-button-success w-full mt-auto"
                                                label="Buy Now"
                                                onClick={() =>
                                                    this.onClickBuy(item)
                                                }
                                            />
                                        </div>
                                    </div>

                                ))}



                            </div>

                        </div>

                    </>}

                </>
            </NavFooter>
        );
    }
}

export default UserPackages;









