import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from './../../../Service';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import adminUserFields from './adminUser.json';
import LoadingComponent from './../../../LoadingComponent';
import { getFormFields, isFormValid, onChoiceChange, onEmailChange, onNumberChange, onTextChange } from './../../../Utils/formHelper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session/index'
import { trimObj } from './../../../Utils';
import ServerlessService from './../../../Service/ServerlessService';
import { Checkbox } from 'primereact/checkbox';

class CreateAdminUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(adminUserFields, this.props.adminUser ? { password: 'Test@123', passwordConfirm: 'Test@123', ...this.props.adminUser } : {});
        this.state = {
            adminUser: this.formFields.data,
            formValidations: this.formFields.formValidations,
            rolesList: []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    isAdditionalValid = () => {

        let adminUser = JSON.parse(JSON.stringify(this.state.adminUser));
        let { formValidations } = this.state;
        let isValid = true;
        if (adminUser.password != adminUser.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }

    saveAdminUser = () => {


        let isCompleteFormValid = true;
        let adminUserData = trimObj(this.state.adminUser)
        const formStatus = isFormValid(adminUserFields, this.formFields.formValidations, adminUserData);
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }



        if (isCompleteFormValid) {
            let url = `/admin/save`
            if (this.props.adminUser) {
                url = `/admin/update-user-privileges`
            }


            this.setState({
                isLoading: true,
            });

            this.serverlessService.post(url, adminUserData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.adminUser ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
            })

        }

    }


    getRoles = () => {
        this.setState({
            isLoading: true,
        });

        let url = `/authorize/getRolesNamesids`

        this.serverlessService.get(url, true).then((res) => {

            if (res && res.status && res.res.status) {
                this.setState({
                    rolesList: res.res.data,
                    isLoading: false,
                }, () => {


                })
            } else {
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (<div>
            <Button label={`${this.props.adminUser ? 'Update' : 'Save'} Admin User`} className='sp-m-lr10'
                onClick={this.saveAdminUser}
            />
        </div>)
    }


    render() {
        const { adminUser, rolesList, formValidations } = this.state;

        return (<>
            <Dialog header={`User Privileges`}
                blockScroll={true}
                draggable={false}
                closeOnEscape={true}
                dismissableMask={true}
                visible={true}
                footer={this.footer}
                closable={true}
                style={{ width: '90%', height: '90vh' }}
                onHide={this.props.onHide}>
                <div>
                    <div className="p-grid row">
                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Content Writer
                            </label>
                            <Checkbox inputId={'isContentWriter'}
                                checked={adminUser.isContentWriter}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isContentWriter",
                                        this,
                                        adminUserFields,
                                        adminUser,
                                        formValidations,
                                        "adminUser",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            {formValidations && !formValidations.fields['isContentWriter'].isValid && <p className="p-error">{formValidations.fields['isContentWriter'].errorMsg}</p>}
                        </div>
                        <div className="col-4">
                            <label className="font-bold block mb-2" >
                                Document Preparator
                            </label>
                            <Checkbox inputId={'isDocumentPreparator'}
                                checked={adminUser.isDocumentPreparator}
                                onChange={(e) => {
                                    onChoiceChange(
                                        e.target.checked,
                                        "isDocumentPreparator",
                                        this,
                                        adminUserFields,
                                        adminUser,
                                        formValidations,
                                        "adminUser",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            {formValidations && !formValidations.fields['isDocumentPreparator'].isValid && <p className="p-error">{formValidations.fields['isDocumentPreparator'].errorMsg}</p>}
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
                {this.state.isLoading && <LoadingComponent />}

            </Dialog>
        </>
        )
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateAdminUser)));
