import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";

class UnassignTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedReason: "", // Store the selected reason
            isLoading: false, // Loading state for API call
        };

        this.serverlessService = new ServerlessService();
    }

    handleReasonChange = (e) => {
        this.setState({
            selectedReason: e.target.value,
        });
    };

    unAssignTable = () => {
        const { _id } = this.props;
        const { selectedReason } = this.state;

        // Check if a reason has been selected
        if (!selectedReason) {
            alert("Please select a reason before unassigning the table.");
            return;
        }

        // Set loading state
        this.setState({
            isLoading: true,
        });

        const url = `/co-space/tables/unassign-user`;
        this.serverlessService
            .put(url, {_id, selectedReason},  true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            selectedReason: "",
                        },
                        () => {
                            this.props.onSuccessFullyUnAssigned();
                        }
                    );
                } else {
                    // Handle API error
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                // Handle API error
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        const { selectedReason, isLoading } = this.state;

        const reasons = [
            { label: "Select a reason", value: "" },
            { label: "Reason 1", value: "Reason 1" },
            { label: "Reason 2", value: "Reason 2" },
            { label: "Reason 3", value: "Reason 3" },
            { label: "Reason 4", value: "Reason 4" },
            { label: "Reason 5", value: "Reason 5" },
        ];

        return (
            <Dialog
                header="Unassign Table"
                visible={true}
                style={{ width: "30vw" }}
                onHide={this.props.onHide}
                footer={
                    <div>
                        <Button
                            label={
                                isLoading ? "Unassigning..." : "Unassign Table"
                            }
                            onClick={this.unAssignTable}
                            disabled={isLoading}
                        />
                    </div>
                }
            >
                <div>
                    <label>Select a reason:</label>
                    <Dropdown
                        value={selectedReason}
                        options={reasons}
                        onChange={(e) =>
                            this.handleReasonChange({
                                target: { value: e.value },
                            })
                        }
                        placeholder="Select a reason"
                    />
                </div>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </Dialog>
        );
    }
}

export default UnassignTable;
