export const trimObj = (obj) => {
    if (obj != null && !Array.isArray(obj) && typeof obj != 'object') return obj;
    return Object.keys(obj).reduce(function (acc, key) {
        acc[key.trim()] = typeof obj[key] == 'string' ? obj[key].trim() : (obj[key] == null || obj[key] == undefined) ? obj[key] : trimObj(obj[key]);
        return acc;
    }, Array.isArray(obj) ? [] : {});
}

export const warningDailogInit = {
    visible: false,
    headerMsg: '',
    message: ''
}



export const getTimeSlotByKey = (key) => {

    switch (key) {
        case 1000:
            return '10:00 AM'
        case 1030:
            return '10:30 AM'
        case 1100:
            return '11:00 AM'
        case 1130:
            return '11:30 AM'
        case 1200:
            return '12:00 PM'
        case 1230:
            return '12:30 PM'
        case 1400:
            return '02:00 PM'
        case 1430:
            return '02:30 PM'
        case 1500:
            return '03:00 PM'
        case 1530:
            return '03:30 PM'
        case 1600:
            return '04:00 PM'
        case 1630:
            return '04:30 PM'
        case 1800:
            return '06:00 PM'
        case 1830:
            return '06:30 PM'
        case 1900:
            return '07:00 PM'
        case 1930:
            return '07:30 PM'

    }

}



export const bytesToSize = (bytes) => {

    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}


export const isValidFileSize = (bytes) => {
    return bytes <= 20971520

}


export const getCredits = (length = 0, language) => {
    if (language == 'english') {
        if (length <= 160) {
            return 1;
        } else {
            return Math.ceil(length / 153)
        }
    } else {
        if (length <= 70) {
            return 1;
        } else {
            return Math.ceil(length / 67)
        }
    }
}


export const calcSegment = (messageBodyText) => {
    if (window.SegmentedMessage) {
        const segmentedMessage = new window.SegmentedMessage(messageBodyText, 'auto');

        segmentedMessage.language = segmentedMessage.encodingName == 'GSM-7' ? 'english' : 'others'
        segmentedMessage.messageLength = segmentedMessage.numberOfUnicodeScalars
        return segmentedMessage;
    } else {
        throw new Error();

    }
}


var gsm = `@ 
£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ^{}\[~]|€ÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà`;

export const calcMessage = (message = '') => {
    let isGSM7 = message.split('').every(a => gsm.indexOf(a) !== -1);

    return {
        language: isGSM7 ? 'english' : 'others',
        length: message.length,
        credits: getCredits(message.length, isGSM7 ? 'english' : 'others'),
        data_coding: isGSM7 ? 0 : 8
    }
}


export const onCopyClick = (text) => {
    navigator.clipboard.writeText(text);
}




function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}


export const formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
});





export const FeeDays = [
    { days: "1 day", id: "1_day" },
    { days: "3 days", id: "3_days" },
    { days: "7 days", id: "7_days" },
    { days: "15 days", id: "15_days" },
    { days: "30 days", id: "30_days" },
    { days: "2 months", id: "2_months" },
    { days: "3 months", id: "3_months" },
    { days: "6 months", id: "6_months" },
    { days: "1 year", id: "1_year" },

];

export const PaymentModes = [
    { type: "Online", id: "online" },
    { type: "Offline", id: "offline" },
];

export const Status = [
    { type: "Pending", id: "pending" },
    { type: "Paid", id: "paid" },
];

export const PolicyTypes = [
    { type: "type1", id: "type1" },
    { type: "type2", id: "type2" },
];

export const Months = [
    { name: "1st Month", id: "1stMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },
    { name: "2nd Month", id: "2ndMonth" },

]
