import React, { Component } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import Service from "./../../../Service";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import Authentication from "./../../../session/index";
import { BasicLazyParams } from "./../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { warningDailogInit } from "./../../../Utils";
import CreateAdminUser from "./createAdminUser";
import Authorize, { PERMISSIONS } from "./../../../session/authorize";
import ServerlessService from "./../../../Service/ServerlessService";
import LoadingComponent from "./../../../LoadingComponent";

class AdminUserPrivileges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowAddAdminUser: false,
            appointments: [],
            lazyParams: {
                ...BasicLazyParams,
                sortField: "-createdAt",
                sortOrder: 1,
            },
            globalSearch: "",
            isShowAdminUser: false,
            appointment: null,
            currentFilter: 1,
            warningDialog: warningDailogInit,
            warningDialog2: warningDailogInit,
            assignedMails: [],
            removeAssignEmail: null,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    showAdminUser = () => {
        this.setState({ isShowAddAdminUser: true, editAdminUser: false });
    };

    onChangeStatusType = (currentFilter) => {
        this.setState((prevState) => {
            return {
                currentFilter: currentFilter,
                lazyParams: BasicLazyParams,
            };
        }, this.getAppointments);
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getAppointments);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getAppointments);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getAppointments);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getAppointments);
    };

    getAppointments = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            appointments: [],
        });
        const url = `/admin/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, { status: this.state.currentFilter }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        appointments: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    editAdminUser = (adminUser) => {


        this.setState({
            isLoading: true,
            appointments: [],
        });
        const url = `/admin/get-user-privileges?id=${adminUser._id}`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                debugger
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        isShowAddAdminUser: true,
                        editAdminUser: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    deleteAdminUser = (rowData) => {
        let data = { ...rowData };

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: "Are you Sure?",
                message: `You want delete the adminUser, it can't be undone once deleted`,
            },
            editAdminUser: data,
        });
    };

    getUserAssignedMails = (userId) => {
        this.setState({
            isLoading: true,
            assignedMails: [],
        });
        const url = `/admin/assigned-mails/${userId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        assignedMails: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    assignEmail = (editAdminUser) => {
        this.setState(
            {
                isShowAssignEmail: true,
                editAdminUser: editAdminUser,
            },
            () => {
                this.getUserAssignedMails(editAdminUser._id);
            }
        );
    };

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDailogInit,
            isLoading: true,
        });

        const url = `/admin/delete`;
        this.serverlessService
            .post(url, { _id: this.state.editAdminUser._id }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            editAdminUser: null,
                            isLoading: false,
                        },
                        () => {
                            this.getAppointments();
                        }
                    );

                    this.toast.show({
                        severity: "success",
                        summary: "Success",
                        detail: "AdminUsers deleted successfully.",
                        life: 3000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    actionsTemplate = (rowData) => {
        return (
            <>
                <div className="p-d-flex sp-mlr10 user-edit-t">
                    <Authorize permId={PERMISSIONS.ADMIN_USER_EDIT}>
                        <span
                            data-pr-tooltip="Edit AdminUser"
                            data-pr-position="bottom"
                            className={`sp-ml10 edit${rowData._id.substr(2)}`}
                            onClick={() => {
                                this.editAdminUser(rowData);
                            }}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip
                            className="table-li-tooltip2"
                            autoHide={false}
                            target={`.edit${rowData._id.substr(2)}`}
                        />
                    </Authorize>
                </div>
            </>
        );
    };

    onSaveAdminUser = (isEdit) => {
        this.toast.show({
            severity: "success",
            summary: `${isEdit ? "Added" : "Modified"}`,
            detail: `AdminUser ${isEdit ? "Edited" : "Created"} Succesfully.`,
            life: 3000,
        });

        this.setState(
            {
                isShowAddAdminUser: false,
                lazyParams: BasicLazyParams,
            },
            () => {
                this.getAppointments();
            }
        );
    };

    componentDidMount() {
        this.getAppointments();
    }

    removeassign = (rowData) => {
        this.setState({
            warningDialog2: {
                visible: true,
                headerMsg: "Are you Sure?",
                message: `You want remove assigned  ${rowData.email} email. Please confirm`,
            },
            removeAssignEmail: rowData,
        });
    };

    onConfirmChange2 = () => {
        this.setState({
            isLoading: true,
            warningDialog2: warningDailogInit,
        });
        const url = `/admin/removeAssign-mail`;
        this.serverlessService
            .post(
                url,
                {
                    userId: this.state.editAdminUser._id,
                    emailId: this.state.removeAssignEmail._id,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        this.getUserAssignedMails(this.state.editAdminUser._id)
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        return (
            <div>
                <div className="h-screen overflow-auto p-4">
                    <span className="text-1000 font-medium text-4xl  mb-3">
                        Admin User Privileges
                    </span>

                    {this.state.isShowAddAdminUser && (
                        <CreateAdminUser
                            adminUser={this.state.editAdminUser}
                            onHide={() => {
                                this.setState({
                                    isShowAddAdminUser: false,
                                });
                            }}
                            onSave={this.onSaveAdminUser}
                        />
                    )}
                    <Authorize permId={PERMISSIONS.ADMIN_USER_LIST}>
                        <div>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                value={this.state.appointments}
                                dataKey="_id"
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                globalFilter={this.state.globalFilter}
                            >
                                <Column field="name" header="Name" />
                                <Column field="phoneNumber" header="Phone Number" />
                                <Column
                                    sortabl
                                    body={this.actionsTemplate}
                                    header="Actions"
                                ></Column>
                            </DataTable>
                        </div>
                    </Authorize>

                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                </div>

                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className="sp-alert-box"
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog.visible}
                    style={{ maxWidth: "22vw", minWidth: "300px" }}
                    footer={() => {
                        return (
                            <div>
                                <Button
                                    label="No"
                                    className="p-button-outlined"
                                    onClick={() => {
                                        this.setState({
                                            warningDialog: warningDailogInit,
                                            editAdminUser: null,
                                        });
                                    }}
                                />
                                <Button
                                    label="Yes"
                                    className="sp-m-lr10"
                                    onClick={this.onConfirmChange}
                                />
                            </div>
                        );
                    }}
                    onHide={() =>
                        this.setState({
                            warningDialog: warningDailogInit,
                            editAdminUser: null,
                        })
                    }
                >
                    <div></div>
                    <p className="sp-alert-head">
                        {this.state.warningDialog.headerMsg}
                    </p>
                    <p className="sp-alert-msg">
                        {this.state.warningDialog.message}
                    </p>
                </Dialog>
                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className="sp-alert-box"
                    dismissableMask={true}
                    closable={true}
                    visible={this.state.isShowAssignEmail}
                    style={{ width: "90%", height: "90vh" }}
                    onHide={() =>
                        this.setState({
                            isShowAssignEmail: false,
                            editAdminUser: null,
                        })
                    }
                >
                    <div>
                        <div className="card sp-datatable sp-m30 ">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                value={this.state.assignedMails}
                                dataKey="_id"
                                lazy
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.assignedMails.length}
                                paginator
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100, 200, 300, 500,
                                ]}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                            >
                                <Column field="phoneNumber" header="Phone Number" />

                                <Column
                                    body={(rowData) => {
                                        return (
                                            <>
                                                <div className="p-d-flex sp-mlr10 user-edit-t">
                                                    <span
                                                        data-pr-tooltip="Assign"
                                                        data-pr-position="bottom"
                                                        className={`sp-ml10 edit${rowData._id.substr(
                                                            2
                                                        )}`}
                                                        onClick={() => {
                                                            this.removeassign(
                                                                rowData
                                                            );
                                                        }}
                                                    >
                                                        <i className="pi pi-times" />
                                                    </span>
                                                    <Tooltip
                                                        className="table-li-tooltip2"
                                                        autoHide={false}
                                                        target={`.edit${rowData._id.substr(
                                                            2
                                                        )}`}
                                                    />
                                                </div>
                                            </>
                                        );
                                    }}
                                    header="Actions"
                                ></Column>
                            </DataTable>
                        </div>

                        { }
                    </div>
                </Dialog>
                <Dialog
                    draggable={false}
                    blockScroll={true}
                    closeOnEscape={true}
                    className="sp-alert-box"
                    dismissableMask={true}
                    closable={false}
                    visible={this.state.warningDialog2.visible}
                    style={{ maxWidth: "22vw", minWidth: "300px" }}
                    footer={() => {
                        return (
                            <div>
                                <Button
                                    label="No"
                                    className="p-button-outlined"
                                    onClick={() => {
                                        this.setState({
                                            warningDialog2: warningDailogInit,
                                            removeAssignEmail: null,
                                        });
                                    }}
                                />
                                <Button
                                    label="Yes"
                                    className="sp-m-lr10"
                                    onClick={this.onConfirmChange2}
                                />
                            </div>
                        );
                    }}
                    onHide={() =>
                        this.setState({
                            warningDialog2: warningDailogInit,
                            editAdminUser: null,
                        })
                    }
                >
                    <div></div>
                    <p className="sp-alert-head">
                        {this.state.warningDialog2.headerMsg}
                    </p>
                    <p className="sp-alert-msg">
                        {this.state.warningDialog2.message}
                    </p>
                </Dialog>

                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});

export default connect(mapStateToProps, {})(Authentication(AdminUserPrivileges));
