import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { Checkbox } from 'primereact/checkbox'
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";

import {
    getFormFields,
    isFormValid,
    onTextChange,
    onDropDownChange,
    onNumberChange,
    onEmailChange,
    onChoiceChange
} from "../../../Utils/formHelper";
import { trimObj } from "../../../Utils";
import ConsultantFields from "./addConsultant.json";

class AddTable extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(ConsultantFields, this.props.editData || {});
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }
    addOrUpdate = () => {
        let consultant = trimObj(this.state.consultant);

        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );
        console.log("formStatus.formValidations.isFormValid", formStatus.formValidations.isFormValid);
        console.log("formStatus.formValidations", formStatus.formValidations);
        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editData) {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/consultant`;

                this.serverlessService
                    .put(url, this.state.consultant, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    consultant: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onTableAddOrUpdate();

                                    // Show success toast
                                    this.toast.show({
                                        severity: "success",
                                        summary: "Success",
                                        detail: "Table updated successfully",
                                        life: 3000,
                                    });
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occured",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occured",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/consultant/add`;
                this.serverlessService
                    .post(url, this.state.consultant, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    consultant: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onTableAddOrUpdate();


                                },
                                console.log("Table added successfully"),
                                // Show success toast
                                this.toast.show({
                                    severity: "success",
                                    summary: "Success",
                                    detail: "Table added successfully",
                                    life: 3000,
                                }),
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occured",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occured",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };


    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className="p-button-text hover:bg-primary"
                onClick={this.props.onHide}
            />
            <Button
                label={this.props.editData ? "Update" : "Add"}
                onClick={this.addOrUpdate}
                autoFocus
                className="p-button-primary "
            />
        </div>
    );






    componentDidMount() {
    }
    render() {
        const { consultant, formValidations } = this.state;
        return (
            <div>
                <Dialog
                    header={this.props.editData ? "Edit Consultant" : "Add Consltant"}
                    visible={true}
                    draggable={false}
                    style={{ width: "65vw" }}
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                >
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Name
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "name",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.name}
                            />
                            {formValidations &&
                                !formValidations.fields["name"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["name"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Phone Number
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "mobile",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.mobile}
                            />
                            {formValidations &&
                                !formValidations.fields["mobile"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["mobile"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Email
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onEmailChange(
                                        e.target.value,
                                        "email",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.email}
                            />

                            {formValidations &&
                                !formValidations.fields["email"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "email"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Role Prefrered
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "rolePrefered",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.rolePrefered}
                            />

                            {formValidations &&
                                !formValidations.fields["rolePrefered"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "rolePrefered"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Technologies Prefrered
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "technologiesPrefered",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.technologiesPrefered}
                            />

                            {formValidations &&
                                !formValidations.fields["technologiesPrefered"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "technologiesPrefered"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-4">
                            <label className="font-bold block mb-2">
                                Willing to Relocate
                            </label>
                            <Checkbox inputId={'33'}
                                checked={consultant.willingToRelocate}
                                onChange={(e) => {
                                    debugger
                                    onChoiceChange(
                                        e.target.checked,
                                        "willingToRelocate",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                            ></Checkbox>
                            {/* <label htmlFor={'33'} className="p-checkbox-label p-name">{''}</label> */}

                            {formValidations &&
                                !formValidations.fields["willingToRelocate"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "willingToRelocate"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>

                </Dialog>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.state.isLoading && <LoadingComponent />}
            </div>
        );
    }
}

export default AddTable;
