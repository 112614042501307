import React, { Component } from "react";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { ScrollTop } from "primereact/scrolltop";
import { Accordion, AccordionTab } from "primereact/accordion";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import NavFooter from "../navFooter";

import "./styles.scss"; // Import your custom CSS for styling

class CoSpace extends Component {
    constructor() {
        super();
        this.state = {
            features: [
                {
                    title: "User Management",
                    description:
                        "Take control of access. Easily manage user access and permissions within Co-Space. Ensure that your workspace is secure and that your team members have the right access levels for efficient collaboration. We prioritize security and authenticity. All user profiles are verified, providing you with peace of mind that your workspace is in safe hands.",
                    image: "user-management-image.jpg",
                },
                {
                    title: "Table Management",
                    description:
                        "Effortless table organization. Co-Space's Table Management feature allows you to create and organize tables within your workspace. Quickly add, modify, or remove tables as needed, giving you complete control over your workspace layout. Tailor your tables to your unique workspace requirements. With Co-Space, you can add specific details to each table, making it easy to manage your space effectively.",
                    image: "table-management-image.jpg",
                },
                {
                    title: "Fee Management",
                    description:
                        "Manage finances with ease. Co-Space's Fee Management feature simplifies financial transactions for your workspace. Add different fee types, track payments, and ensure your workspace remains financially secure. Easily access and review the history of financial transactions within your workspace. Co-Space provides a comprehensive view of all financial activities.",
                    image: "fee-management-image.jpg",
                },
                {
                    title: "Dashboard",
                    description:
                        "All-in-One Workspace Management. The Co-Space Dashboard is the central hub for all your workspace operations. With a user-friendly interface, you can access all the features in one place, enhancing your workspace management experience. Efficiently manage tables, users, and financial transactions directly from the dashboard. Co-Space simplifies the workspace management process, saving you time and effort.",
                    image: "dashboard-image.jpg",
                },
            ],
        };
    }

    featureTemplate(feature, layout) {
        return (
            <div
                className={`p-col-12 p-md-4 ${layout}-box`}
                key={feature.title}
            >
                <Card title={feature.title}>
                    <img
                        src={`images/${feature.image}`}
                        alt={feature.title}
                        className="feature-image"
                    />
                    <p>{feature.description}</p>
                </Card>
            </div>
        );
    }

    render() {
        const pricingPlans = [
            {
                title: "Basic Plan",
                description: "Ideal for getting started",
                price: "$49/month",
                days: "30 days",
            },
            {
                title: "Standard Plan",
                description: "Our most popular plan",
                price: "$99/month",
                days: "60 days",
            },
            {
                title: "Premium Plan",
                description: "For advanced users",
                price: "$149/month",
                days: "90 days",
            },
            {
                title: "Enterprise Plan",
                description: "Custom solutions for businesses",
                price: "Contact us",
                days: "Custom",
            },
            {
                title: "Free Plan",
                description: "Try it for free",
                price: "Free",
                days: "7 days",
            },
        ];
        return (
            <NavFooter>
                <div>
                    {/* ... Your navigation and footer components ... */}
                    <div
                        className="bg-white px-4 md:px-6 lg:px-8 itro-card"
                        style={{
                            display: "flex",
                            backgroundColor: "#FF5733",
                            animation: "slide-in 1s both",
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                textAlign: "center",
                                margin: "10px",
                                marginTop: "30px",
                            }}
                        >
                            <h1>Welcome to CoSpace</h1>
                            <h4
                                style={{
                                    lineHeight: "1.5",
                                    color: "#999",
                                    textAlign: "left",
                                    margin: "5px",
                                }}
                            >
                                The Co-space Admin Website is an exclusive
                                platform dedicated to administrators, offering a
                                comprehensive set of tools to streamline and
                                optimize workspace management. Admins have the
                                power to manage workspace bookings, monitor
                                real-time space availability, and facilitate
                                visitor management with ease.
                            </h4>
                        </div>
                        <div
                            style={{
                                flex: 1,
                                textAlign: "center",
                                margin: "10px",
                                marginTop: "30px",
                            }}
                        >
                            <img
                                src="https://images.unsplash.com/photo-1515965885361-f1e0095517ea?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjR8fG9mZmljZSUyMHNwYWNlcyUyMGhkJTIwcGhvdG9zfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60"
                                alt="co-space Image"
                            />
                        </div>
                    </div>
                    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
                        <div className="mb-3 font-bold text-3xl">
                            <span
                                className="text-900"
                                style={{ fontSize: "inherit" }}
                            >
                                One Product,{" "}
                            </span>
                            <span
                                className="text-blue-600"
                                style={{ fontSize: "inherit" }}
                            >
                                Our Features
                            </span>
                        </div>
                        <div className="text-700 mb-6">
                            We designed our platform considering below points.
                        </div>
                        <div className="p-grid">
                            {this.state.features.map((feature) => (
                                <div
                                    className="surface-card p-4 shadow-2 border-round"
                                    style={{ margin: "20px" }}
                                >
                                    <div className="text-3xl font-medium text-900 mb-3">
                                        {feature.title}
                                    </div>
                                    <div
                                        style={{ height: "150px" }}
                                        className="surface-border"
                                    >
                                        {feature.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{ backgroundColor: "#F8F8FF", padding: "20px" }}
                    >
                        <h3>Why Choose Us?</h3>
                        <ul
                            style={{
                                padding: "30px",
                                margin: "10px",
                            }}
                        >
                            <li>
                                <strong>User Management:</strong> Secure,
                                verified profiles for efficient collaboration.
                            </li>
                            <li>
                                <strong>Table Management:</strong> Customize and
                                organize your workspace layout.
                            </li>
                            <li>
                                <strong>Branches:</strong> Manage multiple
                                locations with ease.
                            </li>
                            <li>
                                <strong>Transactions:</strong> Monitor and track
                                financial activities transparently.
                            </li>
                        </ul>
                    </div>
                    <div
                        className="p-grid"
                        style={{ height: "fit-content", padding: "20px" }}
                    >
                        <h1>Pricing</h1>
                        <div className="p-grid p-justify-start">
                            {pricingPlans.map((plan, index) => (
                                <div
                                    className="grid"
                                    style={{
                                        display: "inline-flex",
                                        margin: "5px",
                                    }}
                                >
                                    <div
                                        className="col-12 lg:col-4 p-3"
                                        style={{
                                            width: "250px",
                                            height: "400px",
                                            margin: "20px",
                                            textAlign: "center",
                                        }}
                                    >
                                        <div className="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
                                            <div className="p-4">
                                                <div className="flex align-items-center">
                                                    <span
                                                        className="inline-flex border-circle align-items-center justify-content-center bg-green-100 mr-3"
                                                        style={{
                                                            width: "38px",
                                                            height: "38px",
                                                        }}
                                                    >
                                                        <i className="pi pi-globe text-xl text-green-600"></i>
                                                    </span>
                                                    <span className="text-900 font-medium text-2xl">
                                                        {plan.title}
                                                    </span>
                                                </div>
                                                <div className="text-900 my-3 text-xl font-medium">
                                                    {plan.price}
                                                </div>
                                                <p className="mt-0 mb-3 text-700 line-height-3">
                                                    {plan.days}
                                                </p>
                                            </div>
                                            <div className="px-4 py-3 surface-100">
                                                <Button
                                                    icon="pi pi-arrow-right"
                                                    label="Sign Up"
                                                    className="p-button-rounded p-button-success"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                        <div className="font-bold text-900 text-6xl mb-3 text-center">
                            A simple steps to Begin
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full lg:w-6 xl:w-4 p-5 hover:shadow-4">
                                {/* ... Registration step ... */}
                            </div>
                            <div className="w-full lg:w-6 xl:w-4 p-5 hover:shadow-4 bg-red">
                                {/* ... Search step ... */}
                            </div>
                            <div className="w-full lg:w-6 xl:w-4 p-5 hover:shadow-4">
                                {/* ... Interaction step ... */}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 py-8 md:px-6 lg:px-8">
                        <Accordion activeIndex={0}>
                            <AccordionTab
                                header={`How to register on ${this.props.orgName}?`}
                            >
                                <p className="m-0">
                                    Register on {this.props.orgName} for free by
                                    providing necessary details.
                                </p>
                            </AccordionTab>
                            <AccordionTab
                                header={`How ${this.props.orgName} store your data?`}
                            >
                                <p className="m-0">
                                    {this.props.orgName} stores your data
                                    securely on the cloud.
                                </p>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});

export default connect(mapStateToProps, {})(withRouter(CoSpace));
