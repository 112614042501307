import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react'
import NavFooter from '../../navFooter'
import Service from '../../../Service';
import ServerlessService from '../../../Service/ServerlessService';
import { BasicLazyParams } from '../../../Utils/constants';
import Checkout from './checkout';

export default class Packages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddProfile: false,
            profiles: [],
            lazyParams: { ...BasicLazyParams, sortField: 'updatedAt', sortOrder: 1 },
            globalSearch: '',
            isCheckout: true
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    getProfiles = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            profiles: []
        });
        const url = `/mvisited/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField ? `&sort=${lazyParams.sortOrder == 1 ? '' : '-'}${lazyParams.sortField}` : ''}`;
        this.serverlessService.post(url, {}, true).then((res) => {


            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false,
                    profiles: res.res.data,
                    totalRecords: res.res.totalRecords
                })

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occured', detail: res.message, life: 3000 });
            }

        }).catch(e => {
            console.log(e);
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occured', detail: e.message, life: 3000 });
        })
    }


    onPaymentSuccuess(obj) {
        console.log(obj);
        
    }

    openPaymentWindow = (data) => {
        var thisObj = this;
        let options = {
            "key": data.key,
            "amount": data.paymentInit.amount,
            "name": "Q GROUP MEDIA",
            "currency": "INR",
            "order_id": data.paymentInit.id,
            "description": "Please complete the payment.",
            "handler": function (response) {
                thisObj.onPaymentSuccuess(response);
            },
          
            "theme": {
                "color": "#ca1c1c"
            }
        };
        var thisWindow = window;
        let rzp = new thisWindow.Razorpay(options);
        rzp.open();
    }


    _submitForm() {
        if (this.isFormValid()) {
            let requestObj = this.state.donationForm;
            requestObj._id = Date().toString();
            if (requestObj._id == '') {
                requestObj._id = null;
            }
            this.setState({
                isLoading: true
            });
            this.service.postDataOrError(URLs.raiseDonationRequest, requestObj, false, false).then((res) => {
                if (res.status && res.statuscode === 23) {
                    this.setState({
                        response: res.data,
                        isLoading: false,
                        showModel: false,
                        donationForm: {
                            _id: this.props.User && this.props.User.User && this.props.User.User._id ? this.props.User.User._id : '',
                            firstName: this.props.User && this.props.User.User && this.props.User.User.firstName ? this.props.User.User.firstName : '',
                            phoneNumber: this.props.User && this.props.User.User && this.props.User.User.phoneNumber ? this.props.User.User.phoneNumber : '',
                            email: this.props.User && this.props.User.User && this.props.User.User.email ? this.props.User.User.email : '',
                            amount: ''
                        }
                    });
                    let data = res.data;

                }
                else {
                    this.setState({
                        isLoading: false,
                        errormsg: res.statusmsg
                    });

                    setTimeout(() => {
                        this.setState({
                            errormsg: ''
                        });
                    }, 5000);
                }
            });
        }
    }


    componentDidMount() {

        let data = {
            "paymentInit": {
                "id": "order_KqpTKdUVB6wTBN",
                "entity": "order",
                "amount": 10000,
                "amount_paid": 0,
                "amount_due": 10000,
                "currency": "INR",
                "receipt": "Matrimony Package",
                "offer_id": null,
                "status": "created",
                "attempts": 0,
                "notes": [],
                "created_at": 1670811084
            },
            "key": "rzp_test_0culdOcTScw84I"
        };

      
    }



    render() {
        return (
            <NavFooter>
                {this.state.isCheckout ? <Checkout /> :
                    <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                        <div className="text-900 font-bold text-6xl mb-4 text-center">Pricing Plans</div>
                        <div className="text-700 text-xl mb-6 text-center line-height-3">We are collecting this money to maintain the platform more securely and the verification charges for each individual profile.</div>

                        <div className="grid">
                            <div className="col-12 lg:col-4">
                                <div className="p-3 h-full">
                                    <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                                        <div className="text-900 font-medium text-xl mb-2">P 30</div>
                                        <div className="text-600">You can access to 30 profiles </div>
                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                        <div className="flex align-items-center">
                                            <span className="font-bold text-2xl text-900"> ₹ 1000</span>
                                            <span className="ml-2 font-medium text-600">Including GST</span>
                                        </div>
                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Unlimited Validity</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Unlimited Profiles</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>100% Verified Profiles</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Verified Contact Details</span>
                                            </li>
                                        </ul>
                                        <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                                        <Button label="Buy Now" className="p-3 w-full mt-auto" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 lg:col-4">
                                <div className="p-3 h-full">
                                    <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                                        <div className="text-900 font-medium text-xl mb-2">P 50</div>
                                        <div className="text-600">You can access to 50 profiles </div>
                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                        <div className="flex align-items-center">
                                            <span className="font-bold text-2xl text-900"> ₹ 1500</span>
                                            <span className="ml-2 font-medium text-600">Including GST</span>
                                        </div>
                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Unlimited Validity</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Unlimited Profiles</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>100% Verified Profiles</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Verified Contact Details</span>
                                            </li>
                                        </ul>
                                        <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                                        <Button label="Buy Now" className="p-3 w-full mt-auto" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 lg:col-4">
                                <div className="p-3 h-full">
                                    <div className="shadow-2 p-3 h-full flex flex-column surface-card" style={{ borderRadius: '6px' }}>
                                        <div className="text-900 font-medium text-xl mb-2">P 100</div>
                                        <div className="text-600">You can access to 100 profiles </div>
                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                        <div className="flex align-items-center">
                                            <span className="font-bold text-2xl text-900"> ₹ 2800</span>
                                            <span className="ml-2 font-medium text-600">Including GST</span>
                                        </div>
                                        <hr className="my-3 mx-0 border-top-1 border-none surface-border" />
                                        <ul className="list-none p-0 m-0 flex-grow-1">
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Unlimited Validity</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Unlimited Profiles</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>100% Verified Profiles</span>
                                            </li>
                                            <li className="flex align-items-center mb-3">
                                                <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                                <span>Verified Contact Details</span>
                                            </li>
                                        </ul>
                                        <hr className="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
                                        <Button label="Buy Now" className="p-3 w-full mt-auto" />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                }



            </NavFooter>
        )
    }
}
