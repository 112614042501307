import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from './../../Service/config';

const dispatchRequestOrgData = data => ({
    type: ACTIONS.REQUEST_ORG,
    data
});

const dispatchReceivedOrgData = (data) => ({
    type: ACTIONS.RECEIVED_ORG,
    data
});

const dispatchErrorOrgData = err => ({
    type: ACTIONS.ERROR_ORG,
    err
});

const dispatchClearOrgData = () => ({
    type: ACTIONS.CLEAR_ORG_DATA
});



const dispatchSavePricing = data => ({
    type: ACTIONS.REQUEST_SAVE_PRICING,
    data
});

const dispatchRecieveSavePricing = data => ({
    type: ACTIONS.RECEIVED_SAVE_PRICING,
    data
});

const dispatchErrorSavePricing = data => ({
    type: ACTIONS.ERROR_SAVE_PRICING,
    data
});




const dispatchUpdateOrg = data => ({
    type: ACTIONS.REQUEST_UPDATE_ORG,
    data
});

const dispatchRecieveUpdateOrg = data => ({
    type: ACTIONS.RECEIVED_UPDATE_ORG,
    data
});

const dispatchErrorUpdateOrg = data => ({
    type: ACTIONS.ERROR_UPDATE_ORG,
    data
});


export const getOrgData = (withoutMeluhaBoard) => dispatch => {
    let _data;

    dispatch(dispatchRequestOrgData(withoutMeluhaBoard));
    axios({
        method: 'get',
        url: `${SERVERLESS_URL}/organization/data`
        // headers: {
        //     'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        // }
    }).then((res) => {
        _data = res.data

        if (res && res.data && res.data.data && res.data.data) {
            const {
                assignedModules,
                logoRectangle,
                logoSquare,
                _id,
                orgName,
                isSuper,
                orgCode,
                emailConfig,
                emailPricings,
                smsPricings,
                contactNumber1,
                contactNumber2, contactEmail, address, city, state, country, pincode,
                logoVisible, brandNameVisible, theme, logoSize
            } = res.data.data;

            dispatch(dispatchReceivedOrgData({
                assignedModules,
                logoRectangle,
                logoSquare,
                _id,
                orgName,
                isSuper,
                orgCode,
                emailConfig,
                emailPricings,
                smsPricings,
                contactNumber1,
                contactNumber2, contactEmail, address, city, state, country, pincode,
                logoVisible, brandNameVisible, theme, logoSize
            }));
        } else {
            dispatch(dispatchErrorOrgData({
                message: 'Some error occured'
            }));
        }



    }).catch(e => {
        dispatch(dispatchErrorOrgData(e));
    });
}


export const clearOrgData = () => dispatchClearOrgData();



export const savePricing = (pricings) => dispatch => {
    let _data;

    dispatch(dispatchSavePricing());
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/organization/update-pricings`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: pricings
    }).then((res) => {
        _data = res.data

        if (res && res.data && res.data.data && res.data.data) {
            const {
                emailPricings, smsPricings
            } = res.data.data;

            dispatch(dispatchRecieveSavePricing({
                emailPricings, smsPricings
            }));
        } else {
            dispatch(dispatchErrorSavePricing({
                message: 'Some error occured'
            }));
        }



    }).catch(e => {
        dispatch(dispatchErrorSavePricing(e));
    });
}





export const updateOrgFromClient = (data) => dispatch => {
    let _data;

    dispatch(dispatchUpdateOrg());
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/organization/update-orgcli`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: data
    }).then((res) => {
        _data = res.data

        if (res && res.data && res.data.data && res.data.data) {
            const {
                logoVisible, brandNameVisible, theme, logoSquare, logoSize
            } = res.data.data;

            dispatch(dispatchRecieveUpdateOrg({
                logoVisible, brandNameVisible, theme, logoSquare, logoSize
            }));
        } else {
            dispatch(dispatchErrorUpdateOrg({
                message: 'Some error occured'
            }));
        }



    }).catch(e => {
        dispatch(dispatchErrorUpdateOrg(e));
    });
}