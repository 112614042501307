import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import LoadingComponent from "../../../LoadingComponent";
import AddBranch from "./addBranch";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Dialog } from "primereact/dialog";

class BranchDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            branch: null,
            editBranch: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    deleteBranch = () => {
        this.setState({
            isLoading: true,
        });

        var _id = this.state.selectedId

        const url = `/co-space/branch/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                //
                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getBranches();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };


    showDeleteBranchDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id
        })
    }
    getBranches = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            branch: [],
        });

        const url = `/co-space/branch?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    console.log(res?.res?.data, "rents data");
                    this.setState({
                        isLoading: false,
                        branch: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getFeeDetails);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getFeeDetails);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getFeeDetails);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getFeeDetails);
    };

    componentDidMount() {
        this.getBranches();
    }

    onHide = () => {
        this.setState({
            visible: false,
            editBranch: null,
        });
    };

    onBranchAddorEdit = () => {
        this.setState(
            {
                visible: false,
                editBranch: null,
            },
            this.getBranches
        );
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-4 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.BRANCHES_DELETE}>
                        <span
                            onClick={() =>
                                this.showDeleteBranchDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete Branch"
                            data-pr-position="top"
                            className={`p-text branchDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.branchDelete${rowData._id}`} />
                    </Authorize>
                </div>

                <div className="col-4 ml-2 mr-2">
                    <Authorize permId={PERMISSIONS.BRANCHES_EDIT}>
                        <span
                            onClick={() =>
                                this.setState({
                                    editBranch: rowData,
                                    visible: true,
                                    isEdit: true,
                                })
                            }
                            data-pr-tooltip="Edit Branch"
                            data-pr-position="top"
                            className={`p-0 branchEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.branchEdit${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };

    footerContent = () => {
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.deleteBranch()}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    }


    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false
        })
    }

    render() {
        const { branch } = this.state;
        return (
            <>
                <div className="p-4">
                    <span className="text-1000 font-medium text-2xl  mb-3">
                        Branches
                    </span>
                    <br />
                    <Authorize permId={PERMISSIONS.BRANCHES_ADD}>

                        <Button
                            label="Add Branch"
                            className=" p-button-primary "
                            severity="warning"
                            onClick={() => this.setState({ visible: true })}
                        />
                    </Authorize>

                    <br></br>
                    <br></br>
                    {this.state.visible && (
                        <AddBranch
                            onHide={this.onHide}
                            onBranchAddorEdit={this.onBranchAddorEdit}
                            editBranch={this.state.editBranch}
                        ></AddBranch>
                    )}
                    <Authorize permId={PERMISSIONS.BRANCHES_DATA}>

                        <div>
                            <DataTable
                                value={branch}
                                lazy
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                scrollHeight="400px"
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                globalFilter={this.state.globalFilter}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column field="name" header="Name"></Column>
                                <Column field="code" header="Code"></Column>
                                <Column field="address" header="Address"></Column>

                                <Column
                                    body={(rowData) => this.actionsBody(rowData)}
                                    header="Actions"
                                ></Column>
                            </DataTable>

                            <Toast
                                ref={(el) => (this.toast = el)}
                                position="bottom-right"
                            />
                            {this.state.isLoading && <LoadingComponent />}
                        </div>
                    </Authorize>
                </div>

                {this.state.isShowConfirmationDialog && <Dialog
                    header="Are you Sure ?"
                    visible={this.state.isShowConfirmationDialog}
                    style={{ width: "15vw" }}
                    onHide={this.onCloseConfirmation}
                    footer={this.footerContent}
                >
                    <p><b>Are you sure ?</b></p>
                    <p>Deleted branch can't be restore.</p>
                </Dialog>}
            </>

        );
    }
}

export default BranchDetails;
