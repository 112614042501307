import React, { Component } from 'react'

export default class Testing extends Component {
    render() {
        return (
            <div>
                <header class="header-section">
                    <div class="header-top">
                        <div class="container">
                            <div class="row justify-content-between">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="header-top-left d-flex">
                                        <div class="support-part">
                                            <a href="tel:+88016558888454"><i class="fa fa-headphones"></i>Support</a>
                                        </div>
                                        <div class="email-part">
                                            <a href="mailto:info@behoof.com"><i class="fa fa-envelope"></i>info@behoof.com</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-6">
                                    <div class="header-top-right d-flex align-items-center justify-content-end">
                                        <div class="language-part">
                                            <i class="fa fa-globe"></i>
                                            <select>
                                                <option>Eng</option>
                                                <option>Ban</option>
                                                <option>Rus</option>
                                                <option>Arb</option>
                                            </select>
                                        </div>
                                        <div class="header-cart-count">
                                            <a href="checkout.html">
                                                <i class="fa fa-shopping-cart"></i>
                                                <span>My cart (0)</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header-bottom">
                        <div class="container">
                            <nav class="navbar navbar-expand-xl">
                                <a class="site-logo site-title" href="home-one.html"><img src="assets/images/logo.png" alt="site-logo" /></a>
                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="menu-toggle"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav main-menu ml-auto">
                                        <li class="active menu_has_children"><a href="#0">Home</a>
                                            <ul class="sub-menu">
                                                <li><a href="home-one.html">Home One</a></li>
                                                <li><a href="home-two.html">Home Two</a></li>
                                                <li><a href="home-three.html">Home Three</a></li>
                                                <li><a href="home-four.html">Home Four</a></li>
                                                <li><a href="home-five.html">Home Five</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="about.html">about</a></li>
                                        <li class="menu_has_children"><a href="#0">Investment</a>
                                            <ul class="sub-menu">
                                                <li><a href="investment.html">Investment One</a></li>
                                                <li><a href="investment-two.html">Investment Two</a></li>
                                                <li><a href="investment-single.html">Investment Single</a></li>
                                            </ul>
                                        </li>
                                        <li class="menu_has_children"><a href="#0">Pages</a>
                                            <ul class="sub-menu">
                                                <li><a href="affiliate.html">Affiliate</a></li>
                                                <li><a href="checkout.html">Checkout</a></li>
                                                <li><a href="contest.html">Contest</a></li>
                                                <li><a href="error-404.html">404 page</a></li>
                                                <li><a href="help.html">Help Page</a></li>
                                                <li><a href="how-work.html">How Work</a></li>
                                                <li><a href="promotion.html">Promotion</a></li>
                                                <li><a href="security.html">Security</a></li>
                                            </ul>
                                        </li>
                                        <li class="menu_has_children"><a href="#0">blog</a>
                                            <ul class="sub-menu">
                                                <li><a href="blog-grid.html">Blog Grid</a></li>
                                                <li><a href="blog-list.html">Blog List</a></li>
                                                <li><a href="blog-single.html">Blog Single</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="contact.html">contact us</a></li>
                                    </ul>
                                    <div class="header-join-part">
                                        <button type="button" class="btn btn-primary btn-hover" data-toggle="modal"
                                            data-target="#signInModal">join us</button>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <section class="choose-us-section pt-120 pb-120 bg_img"
                    data-background="assets/images/elements/choose-us-bg-shape.png">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <div class="section-header text-center">
                                    <span class="section-subtitle">Boost your Money</span>
                                    <h2 class="section-title">Why Should Choose Us</h2>
                                    <p>Our service gives you better result and savings, as per your requirement and you can manage youer
                                        investments from anywhere either form home or work place,any time.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid p-0">
                        <div class="row m-0">
                            <div class="col-lg-12 p-0">
                                <div class="choose-us-slider owl-carousel">
                                    <div class="choose-item text-center">
                                        <div class="choose-thumb">
                                            <img src="assets/images/choose-us/1.png" alt="image" />
                                        </div>
                                        <div class="choose-content">
                                            <h3 class="title">Fast Profit </h3>
                                            <p>We're talking about ways you can make money fast.Invest money and get reward, bonus and profit</p>
                                            <a href="#0" class="read-more-btn">read more<i class="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div class="choose-item text-center">
                                        <div class="choose-thumb">
                                            <img src="assets/images/choose-us/2.png" alt="image" />
                                        </div>
                                        <div class="choose-content">
                                            <h3 class="title">Instant Withdraw</h3>
                                            <p>We’re extremely excited to launch instant withdrawals.you can deposit and withdraw funds in just a
                                                few clicks.</p>
                                            <a href="#0" class="read-more-btn">read more<i class="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div class="choose-item text-center">
                                        <div class="choose-thumb">
                                            <img src="assets/images/choose-us/3.png" alt="image" />
                                        </div>
                                        <div class="choose-content">
                                            <h3 class="title">Dedicated Server</h3>
                                            <p>Dedicated server hosting with 100% guaranteed network uptime.There's no sharing of CPU time, RAM or
                                                bandwidth</p>
                                            <a href="#0" class="read-more-btn">read more<i class="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div class="choose-item text-center">
                                        <div class="choose-thumb">
                                            <img src="assets/images/choose-us/4.png" alt="image" />
                                        </div>
                                        <div class="choose-content">
                                            <h3 class="title">DDoS Protection</h3>
                                            <p>To protect your resources from modern DDoS attacks is through a multi-layer deployment of
                                                purpose-built DDoS mitigation </p>
                                            <a href="#0" class="read-more-btn">read more<i class="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div class="choose-item text-center">
                                        <div class="choose-thumb">
                                            <img src="assets/images/choose-us/5.png" alt="image" />
                                        </div>
                                        <div class="choose-content">
                                            <h3 class="title">24/7 Support</h3>
                                            <p>Our Technical Support team is available for any questions.Our multilingual 24/7 support allows to
                                                keep in touch.</p>
                                            <a href="#0" class="read-more-btn">read more<i class="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div class="choose-item text-center">
                                        <div class="choose-thumb">
                                            <img src="assets/images/choose-us/1.png" alt="image" />
                                        </div>
                                        <div class="choose-content">
                                            <h3 class="title">Fast Profit </h3>
                                            <p>We're talking about ways you can make money fast.Invest money and get reward, bonus and profit</p>
                                            <a href="#0" class="read-more-btn">read more<i class="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
