import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import NavFooter from "../../navFooter";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { BasicLazyParams } from "../../../Utils/constants";
import { CLOUDFRONT_ENDPOINT } from "../../../Service/config";
import ViewProfile from "../common/viewProfile";
import { Toolbar } from "primereact/toolbar";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Authentication from "./../../../session/index";

class Profiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddProfile: false,
            profiles: [],
            lazyParams: {
                ...BasicLazyParams,
                rows: 27,
                sortField: "updatedAt",
                sortOrder: 1,
                disableNext: false
            },
            globalSearch: "",
            isShowWarning: false,
            warningMessage: "",
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    onNextOrPrevious = (next) => {

        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    page:
                        prevState.lazyParams.page +
                        (next ? 1 : prevState.lazyParams.page ? -1 : 0),
                },
            };
        }, this.getProfiles);
    };

    getProfiles = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            profiles: [],
        });
        const url = `/mprofiles/all?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder == 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        profiles: res.res.data,
                        totalRecords: res.res.totalRecords,
                        disableNext: res.res.data.length == 0 ? true : false
                    });
                    if (!res.res?.data?.length) {
                        this.toast.show({
                            severity: "warn",
                            summary: "No more profiles",
                            detail: "",
                            life: 3000,
                        });
                    }
                } else {
                    if (res.res.statusCode == 34 || res.res.statusCode == 32) {
                        this.setState({
                            isLoading: false,
                            isShowWarning: true,
                            warningMessage: res.res.message,
                            warningCode: res.res.statusCode
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Error",
                            detail: res.message,
                            life: 3000,
                        });
                    }
                }
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured a",
                    detail: e.message,
                    life: 3000,
                });
            });
    };


    componentDidMount() {
        this.getProfiles();
    }

    onAfterProfiledAdded = (isEdit) => {
        this.toast.show({
            severity: "success",
            summary: "Success",
            detail: `Profile ${isEdit ? "Edited" : "Added"} Successfully`,
            life: 3000,
        });

        this.setState({
            isAddProfile: false,
        });
    };

    onProfileClick = (_id) => {

        this.setState({
            isShowProfile: true,
            selectedProfileId: _id,
        });
    };



    onBack = () => {
        this.setState({
            isShowProfile: false,
        });
    };

    render() {
        const { isAddProfile, warningCode, disableNext, lazyParams, profiles, warningMessage, isShowWarning } =
            this.state;
        return (
            <NavFooter>
                {this.state.isShowProfile ? (
                    <ViewProfile
                        onBack={this.onBack}
                        isEditHide={true}
                        selectedProfileId={this.state.selectedProfileId}
                    />


                ) : (
                    <>
                        <div className="layout-content">
                            {isShowWarning ? (
                                <>
                                    {
                                        warningCode == 34 && <div className=" shadow-2  surface-0 border-round p-3">
                                            <h4>Welcome to {this.props.orgName}</h4>
                                            <p>Thanks for registering.</p>
                                            <p>Please add your profile to see others profiles by clicking below button.</p>
                                            <Button label="Add profiles" onClick={() => {
                                                this.props.history.push('/my-account')
                                            }} outlined ></Button>
                                        </div>
                                    }
                                </>
                            ) :
                                <div>
                                    <div className="font-bold text-900 text-4xl mb-3 ">
                                        Profiles
                                    </div>
                                    <div className="surface-ground">
                                        <div className="grid">
                                            {profiles?.length ? profiles?.map((profile) => {
                                                return (
                                                    <>

                                                        <div className="col-12 md:col-6 xl:col-3 p-3" key={profile._id}>

                                                            <div className="surface-card shadow-3 border-rounded p-4" onClick={() => {
                                                                this.onProfileClick(
                                                                    profile?._id
                                                                );
                                                            }}>

                                                                <div className="flex flex-column align-items-center border-bottom-1 surface-border pb-3">

                                                                    <img
                                                                        src={
                                                                            CLOUDFRONT_ENDPOINT +
                                                                            profile
                                                                                ?.profileUrl
                                                                                ?.key
                                                                        }
                                                                        alt="avatar-f-1"
                                                                        className="mb-3 w-full"
                                                                        height="100"
                                                                    />
                                                                    <span className="text-2xl text-800 block mb-3 font-semibold">
                                                                        {
                                                                            profile.firstName
                                                                        }{" "}

                                                                    </span>
                                                                    <span className="text-lg text-900 font-medium mb-2">
                                                                        {
                                                                            profile.lastName
                                                                        }
                                                                    </span>
                                                                    <span className="text-600 font-medium mb-3">
                                                                        {
                                                                            profile.profession
                                                                        }
                                                                    </span>

                                                                </div>



                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            }) : null}
                                        </div>
                                        <div className="grid ">
                                            <div className="col-12">
                                                <Toolbar
                                                    className="sp-toolbar"
                                                    left={() => {
                                                        return (
                                                            <Button
                                                                disabled={lazyParams?.page < 1}
                                                                onClick={() =>
                                                                    this.onNextOrPrevious(
                                                                        false
                                                                    )
                                                                }
                                                                className="p-button-outlined p-button-secondary"
                                                                label="Previous"
                                                                icon="pi pi-arrow-left"
                                                            />
                                                        );
                                                    }}
                                                    right={() => {
                                                        return (
                                                            <Button
                                                                disabled={disableNext}
                                                                onClick={() =>
                                                                    this.onNextOrPrevious(
                                                                        true
                                                                    )
                                                                }
                                                                className="p-button-outlined p-button-secondary"
                                                                iconPos="right"
                                                                label="Next"
                                                                icon="pi pi-arrow-right"
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                        <Toast
                            ref={(el) => (this.toast = el)}
                            position="bottom-right"
                        />
                    </>
                )}
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});

export default connect(
    mapStateToProps,
    {}
)(Authentication(withRouter(Profiles)));
