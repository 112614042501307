import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import BlockViewer from '../blockviewer/BlockViewer';
import React, { Component } from 'react';

class DescriptionListNew extends Component {
    render() {
        return (
            <>
                <BlockViewer  containerClassName="surface-section px-4 py-8 md:px-6 lg:px-8" >
                    <div className="surface-section">
                        <div className="font-medium text-3xl text-900 mb-3">Food Item Details</div>
                        <div className="text-500 mb-5"></div>
                        <ul className="list-none p-0 m-0">
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Title</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Heat</div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                </div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Genre</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Chip label="Crime" className="mr-2" />
                                    <Chip label="Drama" className="mr-2" />
                                    <Chip label="Thriller" />
                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                </div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Director</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Michael Mann</div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                </div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Actors</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Robert De Niro, Al Pacino</div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                </div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">Plot</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    A group of professional bank robbers start to feel the heat from police
                                    when they unknowingly leave a clue at their latest heist.</div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                </div>
                            </li>
                        </ul>
                    </div>
                    <Button label="Close" icon="pi pi-pencil" onClick={this.props.onDescriptionClose} />
    
                </BlockViewer>
    
    
    
    
    
            </>
        )
    }
}

export default DescriptionListNew;



    

