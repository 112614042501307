import React, { Component } from 'react';
import {
    getFormFields,
    isFormValid,
    onDropDownChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
import { PolicyTypes } from '../../../Utils/constants';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import AddPolicyType1 from './addPolicyType1';
import { Button } from 'primereact/button';


class PolicyType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            policy: null
        }
    }


    render() {
        const { policy } = this.state;
        const { editPolicy } = this.props;

        console.log(policy, "sadasdasdasd");
        return (
            <>
                <div>
                    <Dialog
                        header={
                            this.props.editPolicy ? "Edit policy" : "Add policy"
                        }
                        visible={true}
                        style={{ width: "65vw" }}
                        onHide={this.props.onHide}
                    // footer={this.footerContent}
                    >
                        <div className="col-4 p-5">
                            <label className="font-bold block mb-2">
                                Policy Type
                            </label>

                            <Dropdown
                                onChange={(e) => {
                                    this.setState({
                                        policy: {
                                            policyType: e.value
                                        }
                                    })
                                }}
                                value={policy?.policyType}
                                options={PolicyTypes}
                                optionValue="id"
                                optionLabel="type"
                                placeholder="Select Policy Type"
                                className="w-full md:w-34rem"
                            />

                        </div>
                        {policy?.policyType == "type1" && (<AddPolicyType1 onpolicyAddorEdit={() => {

                        }} policyType={policy?.policyType} editPolicy={editPolicy}></AddPolicyType1>)}

                    </Dialog>
                </div>

            </>

        );
    }
}

export default PolicyType;



// editPolicy={this.state.editPolicy}
// onPolicyAddOrUpdate={this.onPolicyAddOrUpdate}