import React, { Component } from "react";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Menu } from "primereact/menu";
import BlockViewer from "../blockviewer/BlockViewer";
import ActionPanelNew from "./actionPaneNew";
import AlertNew from "./alertNew";
import CardHeadingNew from "./cardHeadingNew";
import ContainerNew from "./containerNew";
import DescriptionListNew from "./descriptionListNew";
import DetailScreenNew from "./detailsListNew";
import FeedNew from "./feedNew";

class DummyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFavorite:false,
            isOrder : false,
            isShowItems:true,
            isShowAbout:false,
            isShowProcess : false,
            isShowFeed:false,
            foodItems: [
                {
                    name: "Mohair Fox Burger",
                    description: "Duis Aute Irure",
                    price: "9.66",
                    id: "1",
                },
                {
                    name: "Loust Hunter",
                    description: "Excepteur Sint",
                    price: "8.41",
                    id: "2",
                },
                {
                    name: "Cheft The Thief",
                    description: "Culpa Qui Officia",
                    price: "12.55",
                    id: "3",
                },
                {
                    name: "Columbus Burger",
                    description: "Ad Minim Veniam",
                    price: "8.71",
                    id: "4",
                },
                {
                    name: "Sittig Bull",
                    description: "Magna Aliqua",
                    price: "13.45",
                    id: "5",
                },
                {
                    name: "Vespucci Burger",
                    description: "Commodo Consequat",
                    price: "6.75",
                    id: "6",
                },
            ],
        };
    }

    onOrder = () => {
        this.setState({
            isOrder : true,
            isShowItems:false
        })
    }

    onFavorite  = () => {
        this.setState({
            isFavorite : true,
            isShowItems:false
        })
    }

    onCancel = () => {
        this.setState({
            isFavorite:false,
            isShowItems:true
        })
    }

    onProceed = () => {
        this.setState({
            isOrder:false,
            isShowItems:true
        })
    }

    onClickAboutRestaurent= () => {
        this.setState({
            isShowAbout : true,
        })

    }

    onRestaurentContact = () => {
        this.setState({
            isShowContact:true,
            isShowItems:false
        })
    }

    onCloseContact = () => {
        this.setState({
            isShowContact:false,
            isShowItems:true
        })
    }

    onProcess = () => {
this.setState({
    isShowProcess : true,
    isShowItems:false
})
    }

    onProcessClose = () => {
        this.setState({
            isShowProcess:false,
            isShowItems:true
        })
    }

    onDescription = () => {
        this.setState({
            isShowDescription : true,
            isShowItems:false
        })
    }

    onDescriptionClose = () => {
        this.setState ({
            isShowDescription:false,
            isShowItems:true
        })
    }

    onShowAllFoodItems = () => {
        this.setState({
            isShowFeed:true,
            isShowItems:false
        })
    }

    onFeedClose = () => {
        this.setState({
            isShowFeed:false,
            isShowItems:true
        })
    }
    render() {
        const { foodItems } = this.state;
        return (
            <>
            {this.state.isShowItems && <> <div className="block-category-title">Swagath Restaurent</div>
                <BlockViewer
                    header="Food verities"
                    containerClassName="px-4 py-8 md:px-6 lg:px-8"
                >
                    <p><a onClick={() => this.onShowAllFoodItems()}>Show All Food Items ...</a></p>
                    <div className="grid grid-nogutter">
                        {foodItems.map((item) => (
                            <div className="col-12 md:col-6 xl:col-4 p-3">
                                <div className="surface-card shadow-2 border-rounded p-3">
                                    <div className="flex border-bottom-1 surface-border pb-3" onClick={() => this.onDescription()}>
                                        <img
                                            src="assets/images/blocks/burgers/1.png"
                                            style={{
                                                width: "70px",
                                                height: "70px",
                                            }}
                                            className="mr-3"
                                            alt="1"
                                        />
                                        <div className="flex flex-column justify-content-between">
                                            <span className="text-lg text-900 font-medium">
                                                {item.name}
                                            </span>
                                            <span className="text-600 font-medium">
                                                {item.description}
                                            </span>
                                            <div>
                                                <span className="inline-block text-sm text-pink-500 mr-1">
                                                    $
                                                </span>
                                                <span className="text-sm text-900">
                                                    {item.price}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-content-between pt-3">
                                        <Button
                                            label="Favorite"
                                            // icon="pi pi-star-fill"
                                            className="p-button-outlined p-button-secondary w-6 mr-2"
                                            onClick={() => this.onFavorite()}
                                            

                                        />
                                        <Button
                                            label="Order"
                                            // icon="pi pi-shopping-cart"
                                            className="p-button-outlined p-button-secondary w-6 ml-2"
                                            onClick={() => this.onOrder()}

                                        />
                                         <Button label="Process"
                                            className="p-button-outlined p-button-secondary w-6 ml-2"
                                            onClick={() => this.onProcess()}

                                    ></Button>
                                    </div>
                                   
                                </div>
                                
                            </div>
                        ))}
                    </div>
               <Button label="About Restaurent" onClick={() => this.onClickAboutRestaurent()}></Button> 
               <Button label="Contact Us" onClick={() => this.onRestaurentContact()}></Button>


                </BlockViewer></> }

                {this.state.isShowFeed && <FeedNew onFeedClose ={
                    this.onFeedClose
                }></FeedNew>}
               
{this.state.isShowContact && <DetailScreenNew onCloseContact = {this.onCloseContact}></DetailScreenNew>}

                {this.state.isOrder && <ActionPanelNew 
                onProceed = {this.onProceed}

                ></ActionPanelNew>}


                {this.state.isFavorite && <AlertNew 
                onCancel = {this.onCancel}
                ></AlertNew>}

                {this.state.isShowAbout && <CardHeadingNew></CardHeadingNew>}

                {this.state.isShowProcess && <ContainerNew
                onProcessClose = {this.onProcessClose}
                ></ContainerNew>}

                {this.state.isShowDescription && <DescriptionListNew onDescriptionClose = {this.onDescriptionClose}></DescriptionListNew>}
            </>
        );
    }
}

export default DummyComponent;
