import React, { useEffect, useState, useRef } from "react";
import { Menubar } from "primereact/menubar";
import { Dropdown } from "primereact/dropdown";

import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";

import { withRouter, Link } from "react-router-dom";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { TieredMenu } from "primereact/tieredmenu";
import { SERVERLESS_URL } from "../../Service/config";
import { APP_TARGETS } from "../../Utils/constants";

import axios from "axios";
import { saveUserSession } from "../../store/actions";

const NavFooter = (props) => {
    const [isFeatureListVisible, setIsFeatureListVisible] = useState(false);
    const rootBtnRef = useRef(null);
    var menu = useRef(null);
    function onLogout() {
        localStorage.clear();
        props.history.push("/");
        window.location.reload();
    }
    const toggleFeatureList = () => {
        setIsFeatureListVisible(!isFeatureListVisible);
    };

    function initiateGetSession() {
        let userType = localStorage.getItem("userType");

        if (userType) {
            const url = `${SERVERLESS_URL}/admin/session`;

            let config = {
                headers: {
                    Authorization: `Bearer ${
                        localStorage.getItem("Bearer")
                            ? localStorage.getItem("Bearer")
                            : ""
                    }`,
                },
            };
            axios
                .get(url, config)
                .then((res) => {
                    if (res && res.data) {
                        props.saveUserSession(res.data);
                    } else {
                    }
                })
                .catch((err) => {});
        } else {
        }
    }

    useEffect(() => {}, []);

    console.log(props, "rrr");
    const items = [
        {
            label: "Log Out",
            icon: "pi pi-power-off",
            command: () => {
                onLogout();
            },
        },
    ];

    const menuItems = [
        {
            label: "Features ▼",
        },
        {
            label: "Pricing",
        },
        {
            label: "About",
        },
        {
            label: "Contact",
        },
    ];

    return (
        <div>
        <div>
            <div
                className="bg-gray-900 py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static border-bottom-1 border-gray-800"
                style={{
                    minHeight: "84px",
                    position: "relative",
                    top: 0,
                    width: "100%",
                    zIndex: 1000,
                }}
            >
                {process.env.REACT_APP_TARGET === APP_TARGETS.CO_SPACE ? (
                    <img
                        src="assets/images/cospace-logo.jpeg"
                        height={60}
                        width={70}
                        onClick={() => {
                            props.history.push("/");
                        }}
                        alt="hyper"
                    />
                ) : (
                    <img
                        src="assets/images/rings.jpg"
                        height={50}
                        onClick={() => {
                            props.history.push("/");
                        }}
                        alt="hyper"
                    />
                )}
                <StyleClass
                    nodeRef={rootBtnRef}
                    selector="@next"
                    enterClassName="hidden"
                    leaveToClassName="hidden"
                    hideOnOutsideClick
                >
                    <a
                        ref={rootBtnRef}
                        className="cursor-pointer block lg:hidden text-700"
                    >
                        <i className="pi pi-bars text-4xl"></i>
                    </a>
                </StyleClass>

                <ul className="list-none p-0 m-0 flex lg:align-items-center text-900 select-none flex-column lg:flex-row cursor-pointer">
                    {props.user?.isAdmin && (
                        <li>
                            <Link
                                to="/admin"
                                className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150 text-white"
                            >
                                Admin
                            </Link>
                        </li>
                    )}
                    {props.user?.userType == "client" && (
                        <>
                            <li>
                                <Link
                                    to="/profiles"
                                    className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                >
                                    Profiles
                                    <Ripple />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/my-account"
                                    className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                >
                                    My Account
                                    <Ripple />
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/visited-profiles"
                                    className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                >
                                    Visited Profiles
                                    <Ripple />
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="/user-packages"
                                    className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                >
                                    Packages
                                    <Ripple />
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to="/user-orders"
                                    className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                >
                                    Orders
                                    <Ripple />
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
                {props.user?.name ? (
                    <div
                        onClick={(event) => menu.toggle(event)}
                        style={{
                            position: "absolute",
                            right: "20px",
                            top: "8%",
                            transform: "translateY(-50%)",
                            color: "white",
                        }}
                    >
                        {props.user?.name}
                    </div>
                ) : (
                    <>
                        <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                            <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                {menuItems.map((item, index) => (
                                    <li key={index}>
                                        {item.label === "Features ▼" ? (
                                            process.env.REACT_APP_TARGET ===
                                            APP_TARGETS.CO_SPACE ? (
                                                <StyleClass
                                                    selector="@next"
                                                    enterClassName="hidden"
                                                    enterActiveClassName="scalein"
                                                    leaveToClassName="hidden"
                                                    leaveActiveClassName="fadeout"
                                                    hideOnOutsideClick
                                                >
                                                    <a
                                                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                                        onClick={
                                                            toggleFeatureList
                                                        }
                                                    >
                                                        <span>Features</span>
                                                        <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                                        <Ripple />
                                                    </a>
                                                </StyleClass>
                                            ) : process.env.REACT_APP_TARGET ===
                                              APP_TARGETS.MATRIMONY ? (
                                                // Render Matrimony specific features
                                                <div className="p-submenu">
                                                    <div
                                                        onClick={
                                                            toggleFeatureList
                                                        }
                                                    >
                                                        Matrimony Features ▼
                                                    </div>
                                                </div>
                                            ) : null
                                        ) : (
                                            <a
                                                onClick={item.command}
                                                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                            >
                                                <span>{item.label}</span>
                                            </a>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                <li className="border-top-1 border-gray-800 lg:border-top-none">
                                    <div className="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                                        <Button
                                            label="Login"
                                            onClick={() => {
                                                props.history.push("/login");
                                            }}
                                            className="p-button-text font-bold"
                                        />
                                        <Button
                                            label="Sign Up"
                                            onClick={() => {
                                                props.history.push("/signup");
                                            }}
                                            className="ml-3 p-button-outlined font-bold"
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </>
                )}

                <div>
                    {isFeatureListVisible && (
                        <div
                            className="feature-list"
                            style={{
                                position: "absolute",
                                top: "12%",
                                left: "8%" /* Adjust the right distance as needed */,
                                backgroundColor: "#F8F8FF",
                                border: "1px solid #ccc",
                                borderRadius: "3px",
                                padding: "10px",
                                width: "10%",
                                zIndex: 999 /* Ensure it's above other elements */,
                                boxShadow:
                                    "0 0 5px rgba(0, 0, 0, 0.2)" /* Add a subtle box shadow */,
                            }}
                        >
                            <ul
                                style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0,
                                }}
                            >
                                <li
                                    style={{
                                        textAlign: "center",
                                        margin: "10px 0",
                                    }}
                                >
                                    <a
                                        href="/"
                                        style={{
                                            textDecoration: "none",
                                            color: "#333",
                                            transition: "color 0.2s",
                                        }}
                                    >
                                        Tables
                                    </a>
                                </li>
                                <li
                                    style={{
                                        textAlign: "center",
                                        margin: "10px 0",
                                    }}
                                >
                                    <a
                                        href="/"
                                        style={{
                                            textDecoration: "none",
                                            color: "#333",
                                            transition: "color 0.2s",
                                        }}
                                    >
                                        Users
                                    </a>
                                </li>
                                <li
                                    style={{
                                        textAlign: "center",
                                        margin: "10px 0",
                                    }}
                                >
                                    <a
                                        href="/"
                                        style={{
                                            textDecoration: "none",
                                            color: "#333",
                                            transition: "color 0.2s",
                                        }}
                                    >
                                        Branches
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div >
        </div>
        <div style={{ minHeight: "calc(100vh - 0px)" }}>
            {
                props.children
            }
        </div>
        <div className="bg-primary-900 px-4 py-8 md:px-6 lg:px-8">
            <div className="grid">
                <div className="col-12 md:col-3 flex flex-wrap justify-content-start">
                    <img src="assets/images/blocks/hero/rings-2.png" alt="Image" className="w-50 h-50 pt-4" />
                </div>
                <div className="col-12 md:col-3">
                    <div className="text-white font-bold line-height-3 mb-3">Company</div>
                    <div className="text-white mb-3">
                        <i className="pi pi-building border-round p-1 mr-2"></i>
                        {props.orgName}
                    </div>
                    <div className="text-white mb-3">
                        <i className="pi pi-building border-round p-1 mr-2"></i>
                        {props.address}, {props.city}, {props.state},
                    </div>
                    <div className="text-white mb-3">
                        <i className="pi pi-building border-round p-1 mr-2"></i>
                        {props.pincode}, {props.country},
                    </div>
                </div>
                <div className="col-12 md:col-3 text-gray-200">
                    <div className="text-white font-bold line-height-3 mb-3">Contact Details</div>
                    <div className="text-white mb-3">
                        <i className="pi pi-building border-round p-1 mr-2"></i>
                        {props.contactNumber1}
                    </div>
                    <div className="text-white mb-3">
                        <i className="pi pi-building border-round p-1 mr-2"></i>
                        {props.contactNumber2}
                    </div>
                </div>
                <div className="col-12 md:col-3 text-gray-200">
                    <div className="text-white font-bold line-height-3 mb-3">Resourses</div>
                    <div className="text-white mb-3">
                        <i className="pi pi-building border-round p-1 mr-2"></i>
                        <Link to="/terms-condtions"> Terms and Condition Privacy policy </Link>       
                    </div>
                </div>
            </div>
        </div>
        <TieredMenu model={items} popup ref={el => menu = el} />
    </div >
            
        
    )
    
}








const mapStateToProps = (state) => ({
    user: state.user?.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgName: state?.orgData?.orgName,
    address: state?.orgData?.address,
    city: state?.orgData?.city,
    country: state?.orgData?.country,
    state: state?.orgData?.state,
    pincode: state?.orgData?.pincode,
    contactNumber1: state?.orgData?.contactNumber1,
    contactNumber2: state?.orgData?.contactNumber2,
});

export default connect(mapStateToProps, {
    saveUserSession,
})(withRouter(NavFooter));
