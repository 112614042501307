import React, { Component } from 'react'
import NavFooter from '../../navFooter'
import CreditsCard from './creditsCard'

export default class AccountSettings extends Component {
    render() {
        return (
            <NavFooter>

                <div className='m-3'>
                    <CreditsCard />
                </div>

            </NavFooter>
        )
    }
}
