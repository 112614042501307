import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { Component } from 'react'
import ServerlessService from '../../../Service/ServerlessService';

export default class AssignContentWriter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contentWriters: [],
            contentWritingAssignedTo: null,

        }
        this.serverlessService = new ServerlessService();
    }


    getConsultants = () => {
        this.setState({
            isLoading: true,
            contentWriters: [],
        });

        const url = `/admin/get-privilege-users`;
        this.serverlessService
            .post(url, { privilegeType: 'isContentWriter' }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        contentWriters: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getConsultants();
    }


    assignToContentWriter = () => {
        this.setState({
            isLoading: true,

        });

        const url = `/consultants/consultant/assign-to-content-writer`;
        this.serverlessService
            .post(url, {
                _id: this.props.selectedCustomer._id,
                contentWritingAssignedTo: this.state.contentWritingAssignedTo
            }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occured",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occured",
                    detail: e.message,
                    life: 3000,
                });
            });

    }

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onCancel}
            />
            <Button
                label={"Assign"}
                onClick={this.assignToContentWriter}
                autoFocus
                className="p-button-primary"
            />
        </div>
    );

    render() {
        return (
            <Dialog
                header="Assign to content writer"
                visible={true}
                style={{ width: "65vw" }}
                onHide={() =>
                    this.props.onCancel()
                }
                footer={this.footerContent}
            >
                <div>
                    <div className="p-grid row p-3 pl-6 pr-6">
                        <label className="font-bold block mb-2">Content Writer</label>
                        <Dropdown
                            onChange={(e) => {
                                this.setState({
                                    contentWritingAssignedTo: e.value
                                })
                            }}
                            value={this.state.contentWritingAssignedTo}
                            options={this.state.contentWriters}
                            optionValue="_id"
                            optionLabel="name"
                            placeholder="Select a user"
                            className="w-full md:w-14rem"
                        />
                    </div>
                </div>
            </Dialog>
        )
    }
}
