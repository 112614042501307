import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import BlockViewer from '../blockviewer/BlockViewer';
import React, { Component } from 'react';

class FeedNew extends Component {
    render() {
        return (
            <>
                <div className="block-category-title">Feed</div>
                <BlockViewer header="With Cards" >
                    <div className="px-4 py-8 md:px-6 lg:px-8">
                        <div className="mb-5 flex">
                            <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
                                <span className="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
                                    <i className="pi pi-image"></i>
                                </span>
                                <div className="h-full bg-blue-500" style={{ width: '2px', minHeight: '4rem' }}></div>
                            </div>
                            <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                                <div className="mb-3">
                                    <span className="text-900 font-medium inline-block mr-3">Mohair Fox Burger</span>
                                    <span className="text-500 text-sm">1 minute ago</span>
                                </div>
                                <div className="line-height-3 text-700 mb-3">
                                    Eu tincidunt tortor aliquam nulla facilisi cras fermentum.
                                    Sollicitudin nibh sit amet commodo nulla. Mauris in aliquam sem fringilla ut morbi.
                                </div>
                                <div className="text-500 flex align-items-center">
                                    <i className="pi pi-heart mr-1"></i>
                                    <span className="mr-3">0</span>
    
                                    <i className="pi pi-comment mr-1"></i>
                                    <span className="mr-3">1</span>
    
                                    <i className="pi pi-eye mr-1"></i>
                                    <span>24</span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-5 flex">
                            <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
                                <span className="bg-orange-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
                                    <i className="pi pi-image"></i>
                                </span>
                                <div className="h-full bg-orange-500" style={{ width: '2px', minHeight: '4rem' }}></div>
                            </div>
                            <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                                <div className="mb-3">
                                    <span className="text-900 font-medium inline-block mr-3">Loust Hunter</span>
                                    <span className="text-500 text-sm">2 hours ago</span>
                                </div>
                                <div className="line-height-3 text-700 mb-3">
                                    Purus sit amet volutpat consequat mauris. Pretium lectus quam id leo in vitae.
                                    Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper.
                                </div>
                                <div className="text-500 flex align-items-center">
                                    <i className="pi pi-heart mr-1"></i>
                                    <span className="mr-3">26</span>
    
                                    <i className="pi pi-comment mr-1"></i>
                                    <span className="mr-3">6</span>
    
                                    <i className="pi pi-eye mr-1"></i>
                                    <span>673</span>
                                </div>
                            </div>
                        </div>
                        <div className="mb-5 flex">
                            <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
                                <span className="bg-purple-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
                                    <i className="pi pi-image"></i>
                                </span>
                                <div className="h-full bg-purple-500" style={{ width: '2px', minHeight: '4rem' }}></div>
                            </div>
                            <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                                <div className="mb-3">
                                    <span className="text-900 font-medium inline-block mr-3">Cheft the Cheff</span>
                                    <span className="text-500 text-sm">4 hours ago</span>
                                </div>
                                <div className="line-height-3 text-700 mb-3">
                                    Euismod in pellentesque massa placerat duis ultricies lacus.
                                    Vitae sapien pellentesque habitant morbi tristique senectus et netus et.
                                </div>
                                <div className="text-500 flex align-items-center">
                                    <i className="pi pi-heart mr-1"></i>
                                    <span className="mr-3">62</span>
    
                                    <i className="pi pi-comment mr-1"></i>
                                    <span className="mr-3">36</span>
    
                                    <i className="pi pi-eye mr-1"></i>
                                    <span>21</span>
                                </div>
                            </div>
                        </div>

                        <Button label='Close'  onClick={this.props.onFeedClose}></Button>
                    </div>
                </BlockViewer>
    
    
            </>
        )
    }
}

export default FeedNew;


    

 

    

